import { t } from "../helpers/common";

export const NONE = 0;
export const READ = 1;
export const CONTRIBUTE = 2;
export const FULL_CONTROL = 3;

export const LIST = [
  {
    value: NONE,
    translationString: t("permissions.none"),
  },
  {
    value: READ,
    translationString: t("permissions.read"),
  },
  {
    value: CONTRIBUTE,
    translationString: t("permissions.contribute"),
  },
  {
    value: FULL_CONTROL,
    translationString: t("permissions.fullControl"),
  },
];
