import React, { useEffect, useState } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import jwt_decode from "jwt-decode";

// // Redux
import { useDispatch } from "react-redux";
import { setAuthenticated } from "../actions/authentication";

// reactstrap components
import { FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Form } from "reactstrap";

// core components
import SpinnerButton from "../components/Buttons/SpinnerButton";

// schemas
import loginSchema from "../schemas/login";
import { setLogout } from "../actions/authentication";

export default function LocalAuth(props) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    loginSchema
      .validate(formData, { abortEarly: false })
      .then((value) => {
        axios
          .post("/api/auth", value)
          .then((res) => {
            localStorage.setItem("jwtToken", res.data.idToken);

            const authInterceptor = axios.interceptors.request.use(async function(config) {
              config.headers.Authorization = `Bearer ${res.data.idToken}`;

              return config;
            });
            dispatch(
              setLogout(() => {
                localStorage.removeItem("jwtToken");
                axios.interceptors.request.eject(authInterceptor);
                dispatch(setAuthenticated(false));
              })
            );
            dispatch(setAuthenticated(true));
          })
          .catch((error) => {
            setErrors(error.response.data.errors);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors([t("errors.usernameAndPassword")]);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("jwtToken");
    if (token) {
      var decoded = jwt_decode(token);

      if (decoded.exp > Date.now() / 1000) {
        // TODO: check if token is valid
        const authInterceptor = axios.interceptors.request.use(async function(config) {
          config.headers.Authorization = `Bearer ${token}`;

          return config;
        });
        dispatch(
          setLogout(() => {
            localStorage.removeItem("jwtToken");
            axios.interceptors.request.eject(authInterceptor);
            dispatch(setAuthenticated(false));
          })
        );
        dispatch(setAuthenticated(true));
      }
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup className="mb-3">
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-user" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("fields.username")}
            type="text"
            onChange={(e) => {
              setFormData({
                ...formData,
                username: e.target.value,
              });
            }}
            value={formData.username}
          />
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <InputGroup className="input-group-merge input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="fas fa-unlock-alt" />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            placeholder={t("fields.password")}
            type="password"
            onChange={(e) => {
              setFormData({
                ...formData,
                password: e.target.value,
              });
            }}
            value={formData.password}
          />
        </InputGroup>
      </FormGroup>
      {/* TODO: implement remember me */}
      <div className="custom-control custom-control-alternative custom-checkbox">
        <input disabled className="custom-control-input" id=" customCheckLogin" type="checkbox" />
        <label className="custom-control-label" htmlFor=" customCheckLogin">
          <span className="text-muted">{t("fields.rememberMe")}</span>
        </label>
      </div>
      <div className="text-center">
        <SpinnerButton className="mt-4" color="info" isLoading={isLoading} type="submit">
          {t("buttons.signIn")}
        </SpinnerButton>
      </div>
      {errors.length > 0
        ? errors.map((error, errorIndex) => (
            <div key={`error${errorIndex}`} className="text-center text-danger">
              {error}
            </div>
          ))
        : null}
    </Form>
  );
}
