import React, { useEffect, useRef, useState } from "react";

// constants & types
import { ISiteAccessModalProps } from "../types/Modal";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import _ from "lodash";

// components
import { Button, Col, Container, ListGroup, ListGroupItem, Modal, Row } from "reactstrap";

import PermissionGuard from "../components/PermissionGuard";
import LabelTypeahead from "../components/Inputs/LabelTypeahead";
import { BasicUser } from "../types/User";
import { USER_TYPES } from "../constants/userTypes";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LoadingOverlay from "../components/LoadingOverlay";

export default function SiteAccessModal(props: ISiteAccessModalProps) {
  const { id, siteId } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [usersWithAccess, setUsersWithAccess] = useState<BasicUser[]>([]);
  const [selected, setSelected] = useState<BasicUser[]>([]);
  const [users, setUsers] = useState<BasicUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = () => {
    setIsSubmitting(true);
    axios
      .post(`/api/sites/${siteId}/access`, {
        users: selected.map((selected) => selected._id),
      })
      .then((response) => {
        setSelected([]);
        loadSiteUsers();
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(id));
  };

  const loadSiteUsers = () => {
    setIsLoading(true);
    axios
      .get<BasicUser[]>(`/api/sites/${siteId}/access`)
      .then((res) => {
        setUsersWithAccess(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get<BasicUser[]>("/api/users/basic")
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log("err:", err);
      });

    loadSiteUsers();
  }, []);

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close} size="lg">
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.siteAccess")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Container>
          <Row
            style={{
              alignItems: "flex-end",
            }}
          >
            <Col>
              <LabelTypeahead
                label={t("fields.users")}
                multiple
                options={_.differenceBy(users, usersWithAccess, "_id")}
                labelKey={(option: BasicUser) => {
                  switch (option.type) {
                    case USER_TYPES.LOCAL:
                      return `${option.firstName} ${option.lastName}`;
                    case USER_TYPES.AZURE:
                    default:
                      return option.name;
                  }
                }}
                onChange={(value: BasicUser[]) => {
                  setSelected(value);
                }}
                selected={selected}
              />
            </Col>
            <Col xs="auto">
              <SpinnerButton
                color="info"
                isLoading={isSubmitting}
                disabled={selected.length < 1}
                onClick={onSubmit}
              >
                {t("buttons.add")}
              </SpinnerButton>
            </Col>
          </Row>
          <LoadingOverlay isLoading={isLoading}>
            <Row className="mt-4 mb-2">{t("texts.usersWithSiteAccess")}</Row>
            <Row>
              <Col>
                <ListGroup>
                  {usersWithAccess.map((user) => (
                    <ListGroupItem key={user._id}>
                      <UserWithAccess
                        siteId={siteId}
                        user={user}
                        deleteCallback={() => loadSiteUsers()}
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          </LoadingOverlay>
        </Container>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}

interface IUserWithAccessProps {
  siteId: string;
  user: BasicUser;
  deleteCallback: () => void;
}

const UserWithAccess = ({ siteId, user, deleteCallback }: IUserWithAccessProps) => {
  const [isDeleting, setDeleting] = useState(false);

  const onDelete = () => {
    setDeleting(true);

    axios
      .delete(`/api/users/${user._id}/site/${siteId}`)
      .then((res) => {
        deleteCallback();
      })
      .catch((err) => {
        console.log("err:", err);
        setDeleting(false);
      });
  };

  return (
    <Row className="align-items-center">
      <Col>{user.type === USER_TYPES.LOCAL ? `${user.firstName} ${user.lastName}` : user.name}</Col>
      <Col sm="auto">
        <SpinnerButton
          isLoading={isDeleting}
          outline
          color="danger"
          style={{ borderWidth: 0 }}
          onClick={onDelete}
        >
          <i className="fas fa-trash" style={{ marginLeft: 0 }} />
        </SpinnerButton>
      </Col>
    </Row>
  );
};
