import React, { useState, useEffect } from "react";

// constants & types
import { MODULES } from "../../constants/modules";
import MODALS from "../../constants/modals";

import { IPaginationDocs } from "../../types/PaginationData";
import { ITransportFilters, ITransportResponse } from "../../types/Transport";

// utils
import { useTranslation } from "react-i18next";
import _ from "lodash";
import axios from "axios";
import qs from "qs";

//Redux
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { openModal } from "../../features/modals";

// components
import ReportHeader from "../../components/Headers/ReportHeader";
import LoadingOverlay from "../../components/LoadingOverlay";
import { Button, Card, Container, Row, CardHeader, Col, UncontrolledTooltip } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// Parts
import Filters from "./Filters";
import { useParams } from "react-router-dom";
import moment from "moment";
import SpinnerButton from "../../components/Buttons/SpinnerButton";
import PermissionGuard from "../../components/PermissionGuard";
import { ROLE_PERMISSIONS } from "../../constants/rolePermissions";
import { ITransportTypeResponse } from "../../types/TransportType";

function Transports() {
  const { siteId } = useParams<{ siteId: string }>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [initialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);

  // @ts-ignore TODO: fix root state types
  const transportTypes: ITransportTypeResponse[] = useAppSelector((state) => state.transportTypes);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [data, setData] = useState<
    IPaginationDocs<ITransportResponse> & { filters?: ITransportFilters }
  >({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
    sort: { field: "occured", order: "desc" },
  });

  const onTableChange = (type: any, { page, sizePerPage, filters, sortField, sortOrder }: any) => {
    switch (type) {
      case "sort":
        setData((d) => ({ ...d, page: 1, sort: { field: sortField, order: sortOrder } }));
        break;
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  const left = (id: string) => {
    setLoading(true);
    axios
      .post("/api/transports/left", { id, left: moment() })
      .then((res) => {
        loadData();
      })
      .catch((err) => {
        console.log("error:", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadData = () => {
    if (isLoading) {
      return;
    }
    axios
      .get(`/api/transports/site/${siteId}`, {
        params: {
          page: data.page,
          limit: data.limit,
          sort: qs.stringify(data.sort),
          filters: qs.stringify(data.filters),
        },
      })
      .then((response) => {
        setData((d) => ({ ...d, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (initialized) {
      loadData();
    }
  }, [initialized, data.limit, data.page, data.sort, data.filters]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      <ReportHeader
        name={
          <>
            <i className="fas fa-users mr-1" />
            {t("pages.transports")}
          </>
        }
        // onPdfExport={onPdfExport}
        // pdfIsLoading={pdfIsLoading}
        filterCount={_.size(data.filters)}
        toggleFilters={() => {
          setFiltersOpen((f) => !f);
        }}
        filtersNode={
          <Filters
            isOpen={filtersOpen}
            onChange={(filters) => {
              setData((d) => ({ ...d, page: 1, filters }));
            }}
          />
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={false}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      <PermissionGuard
                        module={MODULES.transportRegistry}
                        permission={ROLE_PERMISSIONS.WRITE}
                      >
                        {({ hasAccess }) => (
                          <>
                            <Button
                              className="btn-round btn-icon"
                              disabled={!hasAccess}
                              color="primary"
                              id="tooltip443412080"
                              onClick={() => {
                                dispatch(
                                  openModal({
                                    type: MODALS.Transport,
                                    siteId,
                                    callback: loadData,
                                  })
                                );
                              }}
                              size="sm"
                            >
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-plus" />
                              </span>
                              <span className="btn-inner--text">{t("buttons.add")}</span>
                            </Button>
                            <UncontrolledTooltip delay={0} target="tooltip443412080">
                              {t("tooltips.addNewTransport")}
                            </UncontrolledTooltip>
                          </>
                        )}
                      </PermissionGuard>
                    </Col>
                  </Row>
                </CardHeader>
                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  defaultSorted={[
                    {
                      dataField: "arrived",
                      order: "desc",
                    },
                  ]}
                  columns={[
                    {
                      dataField: "transportTypeId",
                      text: t("fields.type"),
                      // @ts-ignore
                      formatter: (transportTypeId) => {
                        if (!transportTypeId) {
                          return null;
                        }

                        const transportType = transportTypes.find((s) => s._id === transportTypeId);

                        return transportType ? transportType.name : "";
                      },
                      headerStyle: { width: 200 },
                    },
                    {
                      dataField: "driverFirstName",
                      text: t("fields.fullName"),
                      formatter: (cell, row) => {
                        return `${row.driverFirstName} ${row.driverLastName}`;
                      },
                      headerStyle: { width: 200 },
                    },
                    {
                      dataField: "carNumber",
                      text: t("fields.carNumber"),
                      headerStyle: { width: 50 },
                    },
                    {
                      dataField: "notes",
                      text: t("fields.notes"),
                    },
                    {
                      dataField: "arrived",
                      text: t("fields.arrived"),
                      sort: true,
                      formatter: (cell) => {
                        return moment(cell).format("DD.MM.YYYY. H:mm");
                      },
                      headerStyle: {
                        width: 160,
                      },
                    },
                    {
                      dataField: "left",
                      text: t("fields.left"),
                      sort: true,
                      formatter: (cell, row: ITransportResponse) => {
                        if (cell) {
                          return moment(cell).format("DD.MM.YYYY. H:mm");
                        }

                        return (
                          <PermissionGuard
                            module={[MODULES.Calendar, MODULES.transportRegistry]}
                            permission={ROLE_PERMISSIONS.UPDATE}
                          >
                            {({ hasAccess }) => (
                              <SpinnerButton
                                className="btn-icon btn-3"
                                color={hasAccess ? "success" : "default"}
                                disabled={!hasAccess}
                                outline
                                isLoading={isLoading}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  left(row._id);
                                }}
                                size="sm"
                              >
                                <span className="btn-inner--icon">
                                  <i className="fas fa-calendar" />
                                </span>
                                <span className="btn-inner--text">{t("buttons.left")}</span>
                              </SpinnerButton>
                            )}
                          </PermissionGuard>
                        );
                      },
                      headerStyle: {
                        width: 160,
                      },
                    },
                  ]}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      dispatch(
                        openModal({
                          type: MODALS.Transport,
                          siteId,
                          transport: row,
                          callback: loadData,
                        })
                      );
                    },
                  }}
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                />
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Transports;
