import { t } from "../helpers/common";
import { colors } from "./colors";

export enum IconTypes {
    access = "access",
}

export interface IIcons {
    value: IconTypes;
    icon: string;
    tooltipTranslationString: string,
    color: colors;
}

export const LIST: IIcons[] = [
    {
        value: IconTypes.access,
        icon: "fa-sign-in-alt",
        tooltipTranslationString: t("tooltips.userActionAccess", "Accessed the site"),
        color: colors.Success,
    },
];