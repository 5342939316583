import { SET_AUTHENTICATED, SET_USER_DATA, SET_LOGOUT } from "./types";
// import setAuthToken from "../setAuthToken";

export const setAuthenticated = (status) => {
  return {
    type: SET_AUTHENTICATED,
    payload: status,
  };
};

export const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

export const setLogout = (callback) => {
  return {
    type: SET_LOGOUT,
    payload: callback,
  };
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  // setAuthToken(false);
  // dispatch(setCurrentUser({}));
  window.location.href = "";
};
