import React, { useEffect, useState } from "react";

// constants & types
import { IMapModalProps } from "../types/Modal";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";

import { Icon } from "leaflet";

import { Map, TileLayer, Marker, Popup, Circle, Tooltip } from "react-leaflet";

// components
import { Button, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";

import { ISite } from "../types/Site";
import LabelTypeahead from "../components/Inputs/LabelTypeahead";

import LoadingOverlay from "../components/LoadingOverlay";

import Pin from "../assets/img/icons/pin.png";

const icon = new Icon({
  iconUrl: Pin,
  iconSize: [37, 104],
});

export default function MapModal(props: IMapModalProps) {
  const { id, markers, circles } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close} size="lg">
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workflowSites")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Map
          center={[
            markers ? markers[0].location.latitude : 0,
            markers ? markers[0].location.longitude : 0,
          ]}
          zoom={13}
          scrollWheelZoom={false}
          style={{ width: "100%", height: 500 }}
        >
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
          {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          {markers
            ? markers.map((marker) => {
                return (
                  <Marker
                    position={[marker.location.latitude, marker.location.longitude]}
                    // @ts-ignore
                    icon={icon}
                  >
                    {marker.popup ? <Popup>{marker.popup}</Popup> : null}
                  </Marker>
                );
              })
            : null}
          {circles
            ? circles.map((location) => {
                return (
                  <Circle
                    key={location._id}
                    center={[location.latitude, location.longitude]}
                    radius={location.radius}
                    color="#A569BD"
                    bubblingMouseEvents={false}
                  >
                    <Tooltip>{location.name}</Tooltip>
                  </Circle>
                );
              })
            : null}
        </Map>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
