import React, { useState } from "react";

// i18n
import { useTranslation } from "react-i18next";

// utils
import axios from "axios";
import { toast } from 'react-toastify';
import { generatePassword } from "../helpers/security";

// validation
import profilePasswordSchema from "../schemas/profilePassword";

// components
import { Formik } from "formik";
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelPasswordInputGroup from "../components/Inputs/LabelPasswordInputGroup";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

// Redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";

interface IProps {
    modalId: string,
}

interface IValues {
  password: string,
  newPassword: string,
}

export default function ChangePasswordModal(props: IProps) {
  const { modalId } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (values: IValues) => {
    setSubmitting(true);
    axios
      .post("/api/user/changePassword", values)
      .then((res) => {
        toast(t("messages.passwordChanged", "Password changed successfully"));
        close();
      })
      .catch((err) => {
        console.error("err:", err);
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
          newPasswordRepeat: "",
        }}
        validationSchema={profilePasswordSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">{t("modalTitles.changePassword")}</h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.password")}
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                touched={touched.password}
                validationErrors={errors.password}
              />
              <LabelPasswordInputGroup
                label={t("fields.newPassword")}
                name="newPassword"
                onClick={() => {
                  setFieldValue(
                    "password",
                    generatePassword(12)
                  );
                }}
                value={values.newPassword}
                onChange={handleChange}
                touched={touched.newPassword}
                validationErrors={errors.newPassword}
              />
              <LabelFormGroup
                label={t("fields.newPasswordRepeat")}
                name="newPasswordRepeat"
                type="password"
                value={values.newPasswordRepeat}
                onChange={handleChange}
                touched={touched.newPasswordRepeat}
                validationErrors={errors.newPasswordRepeat}
              />
            </div>
            <div className="modal-footer">
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={submitting}
              >
                {t("buttons.save")}
              </SpinnerButton>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
