import React, { useEffect, useState } from "react";

// interfaces
import { ICameraStep, IStepParams } from "../../../../types/WorkflowSteps";
import { workflowStepTypes } from "../../../../constants/workflowSteps";

// constants
import {
  LIST as CAMERA_ORIENTATIONS,
  orientations,
} from "../../../../constants/cameraOrientations";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import cameraSchema from "../../../../schemas/workflowStep/camera";
import { useHistory, useParams } from "react-router-dom";

// components
import { Button, Form } from "reactstrap";
import SpinnerButton from "../../../../components/Buttons/SpinnerButton";

// components
import LabelFormGroup from "../../../../components/Inputs/LabelFormGroup";
import { Formik } from "formik";
import LabelToggle from "../../../../components/Inputs/LabelToggle";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { CardFooter } from "reactstrap/lib";
import LabelSelectFormGroup from "../../../../components/Inputs/LabelSelectFormGroup";
import { workflowRoutes } from "../../../../helpers/apiRoutes";

export default function Camera() {
  const { workflowId, siteId, stepId } = useParams<IStepParams>();

  const history = useHistory();

  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleteing] = useState(false);

  const [initialData, setInitialData] = useState<ICameraStep>({
    type: workflowStepTypes.Camera,
    name: "",
    min: 1,
    max: 5,
    orientation: orientations.Any,
    description: "",
    enableLocation: false,
    enabled: true,
  });

  const onDelete = (_id: any) => {
    setDeleteing(true);
    axios
      .delete(`/api/workflows/${workflowId}/step/${_id}`)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log("err:", err);
        setDeleteing(false);
      });
  };

  const onSubmit = (values: any) => {
    setSubmitting(true);
    axios
      .put(`/api/workflows/${workflowId}/camera`, values)
      .then((response) => {
        setInitialData(response.data);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setSubmitting(false);
      });
  };

  const goBack = () => {
    if (siteId) {
      history.push(`/site/${siteId}/tasks/${workflowId}`);
    } else {
      history.push(`/workflows/${workflowId}`);
    }
  };

  useEffect(() => {
    if (stepId) {
      setLoading(true);
      axios
        .get(workflowRoutes.get.workflowStep(workflowId, stepId))
        .then((res) => {
          setInitialData((e) => ({ ...e, ...res.data }));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err:", err);
        });
    }
  }, []);

  useEffect(() => {
    if (!stepId && initialData._id) {
      if (siteId) {
        history.replace(`/site/${siteId}/tasks/${workflowId}/camera/${initialData._id}`);
      } else {
        history.replace(`/workflows/${workflowId}/camera/${initialData._id}`);
      }
    }
  }, [initialData]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-users mr-1" />
            {t("pages.workflows")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}></Col>
                  </Row>
                </CardHeader>
                <Formik
                  initialValues={initialData}
                  validationSchema={cameraSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    dirty,
                  }) => (
                    <Form role="form" onSubmit={handleSubmit}>
                      <Container>
                        <LabelFormGroup
                          label={t("fields.name")}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          touched={touched.name}
                          validationErrors={errors.name}
                        />
                        <Row>
                          <Col>
                            <LabelFormGroup
                              label={t("fields.minImages")}
                              name="min"
                              type="number"
                              min={0}
                              max={10}
                              value={values.min}
                              onChange={handleChange}
                              touched={touched.min}
                              validationErrors={errors.min}
                            />
                          </Col>
                          <Col>
                            <LabelFormGroup
                              label={t("fields.maxImages")}
                              name="max"
                              type="number"
                              min={1}
                              max={10}
                              value={values.max}
                              onChange={handleChange}
                              touched={touched.max}
                              validationErrors={errors.max}
                            />
                          </Col>
                        </Row>
                        <LabelSelectFormGroup
                          label={t("fields.orientation")}
                          name="orientation"
                          value={values.orientation}
                          onChange={handleChange}
                          options={CAMERA_ORIENTATIONS.map((orientation) => {
                            return {
                              value: orientation.value,
                              name: t(orientation.translationString),
                            };
                          })}
                          touched={touched.orientation}
                          validationErrors={errors.orientation}
                        />
                        <LabelFormGroup
                          label={t("fields.description")}
                          name="description"
                          type="textarea"
                          optional
                          value={values.description}
                          onChange={handleChange}
                          touched={touched.description}
                          validationErrors={errors.description}
                        />
                        <LabelToggle
                          label={t("fields.location")}
                          name="enableLocation"
                          checked={values.enableLocation}
                          onChange={handleChange}
                          touched={touched.enableLocation}
                          validationErrors={errors.enableLocation}
                        />
                        <LabelToggle
                          label={t("fields.enabled")}
                          name="enabled"
                          checked={values.enabled}
                          onChange={handleChange}
                          touched={touched.enabled}
                          validationErrors={errors.enabled}
                        />
                      </Container>
                      <CardFooter>
                        <Container>
                          <Row>
                            {values._id ? (
                              <Col>
                                <SpinnerButton
                                  isLoading={isDeleting}
                                  color="danger"
                                  onClick={() => onDelete(values._id)}
                                >
                                  {t("buttons.delete")}
                                </SpinnerButton>
                              </Col>
                            ) : null}
                            <Col>
                              <Row className="justify-content-end">
                                <Col xs="auto">
                                  <SpinnerButton
                                    isLoading={isSubmitting}
                                    type="submit"
                                    className="ml-auto"
                                    color="primary"
                                    disabled={!dirty}
                                  >
                                    {values._id ? t("buttons.save") : t("buttons.create")}
                                  </SpinnerButton>
                                </Col>
                                <Col xs="auto">
                                  <Button
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                      goBack();
                                    }}
                                  >
                                    {t("buttons.back")}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </CardFooter>
                    </Form>
                  )}
                </Formik>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
