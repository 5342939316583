import * as yup from "yup";

const item = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
});

const checklistSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  description: yup.string().max(2500),
  enabled: yup.bool(),
  items: yup
    .array()
    .test("has-any", "Checklist should have at least item", (value) => {
      if (value) {
        return value.length > 0;
      }

      return false;
    })
    .of(item),
});

export default checklistSchema;
