import { combineReducers } from "redux";
import * as scopes from "../actions/scopes";

// common reducers
import { paginationReducerFor } from "./common/paginationReducer";
import { loadingReducerFor } from "./common/loadingReducer";
import commonReducer from "./common/common";

// new
import rolesReducer from "./roles";
import eventTypesReducer from "./admin/eventTypesReducer";
import transportTypesReducer from "./admin/transportTypesReducer";

import workspacesReducer from "./workspacesReducer";
import accessLogReducer from "./accessLogReducer";
import workflowsReducer from "./workflows";

import eventsReducer from "./eventsReducer";
import usersReducer from "./usersReducer";
import sitesReducer from "./sitesReducer";

import keysReducer from "./keysReducer";
import transportsReducer from "./transportsReducer";
import visitorsReducer from "./visitorsReducer";

// old style
import errorReducer from "./errorReducer";
import modalReducer from "./modalReducer";
import employeesReducer from "./admin/employeesReducer";
import lockersReducer from "./admin/lockersReducer";

import authReducer from "./authReducer";
import userReducer from "./user/userReducer";
// import siteReducer from "./siteReducer";

// new style defination
const eventsCombinedReducer = combineReducers({
  docs: eventsReducer,
  paginationData: paginationReducerFor(scopes.EVENTS),
  loading: loadingReducerFor(scopes.EVENTS),
});

const workspacesCombinedReducer = combineReducers({
  docs: workspacesReducer,
  paginationData: paginationReducerFor(scopes.WORKSPACES),
  loading: loadingReducerFor(scopes.WORKSPACES),
});

const usersCombinedReducer = combineReducers({
  docs: usersReducer,
  paginationData: paginationReducerFor(scopes.USERS),
  loading: loadingReducerFor(scopes.USERS),
});

const sitesCombinedReducer = combineReducers({
  docs: sitesReducer,
  paginationData: paginationReducerFor(scopes.SITES),
  loading: loadingReducerFor(scopes.SITES),
});

const keysCombinedReducer = combineReducers({
  docs: keysReducer,
  paginationData: paginationReducerFor(scopes.KEYS),
  loading: loadingReducerFor(scopes.KEYS),
});

const transportsCombinedReducer = combineReducers({
  docs: transportsReducer,
  paginationData: paginationReducerFor(scopes.TRANSPORTS),
  loading: loadingReducerFor(scopes.TRANSPORTS),
});

const visitorsCombinedReducer = combineReducers({
  docs: visitorsReducer,
  paginationData: paginationReducerFor(scopes.VISITORS),
  loading: loadingReducerFor(scopes.VISITORS),
});

const accessLogCombinedReducer = combineReducers({
  docs: accessLogReducer,
  paginationData: paginationReducerFor(scopes.ACCESS_LOG),
  loading: loadingReducerFor(scopes.ACCESS_LOG),
});

const workflowsCombinedReducer = combineReducers({
  docs: workflowsReducer,
  paginationData: paginationReducerFor(scopes.WORKFLOWS),
  loading: loadingReducerFor(scopes.WORKFLOWS),
});

export default {
  // classifiers
  roles: rolesReducer,
  eventTypes: eventTypesReducer,
  transportTypes: transportTypesReducer,

  // data
  workflows: workflowsCombinedReducer,
  accessLog: accessLogCombinedReducer,
  workspaces: workspacesCombinedReducer,
  events: eventsCombinedReducer,
  users: usersCombinedReducer,
  sites: sitesCombinedReducer,
  keys: keysCombinedReducer,
  transports: transportsCombinedReducer,
  visitors: visitorsCombinedReducer,

  // tmp
  errors: errorReducer,
  modal: modalReducer,
  employees: employeesReducer,
  lockers: lockersReducer,

  auth: authReducer,
  user: userReducer,

  common: commonReducer,

  // NOTE: temporary comented out
  // site: siteReducer
};
