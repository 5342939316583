import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import axios from "axios";

//Redux
import { useAppDispatch } from "../../../hooks/redux";
import { APPROVE_DIALOG_MODAL } from "../../../modals/types";
// TODO: remove openModal usage
import { openModal } from "../../../actions/modals";
// components
import SimpleHeader from "../../../components/Headers/SimpleHeader";

import { Card, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
// import LoadingList from "./../LoadingList";
import { ListGroupItem } from "reactstrap/lib";
import EditableText from "../../../components/EditableText";
import ActionItems, { ActionItemTypes, Triggers } from "../../../components/ActionItems";

// Parts
import ListGroup from "reactstrap/lib/ListGroup";
import { IQrCode, IQrCodeColleciton } from "../../../types/Qr";
import { QRCodeCanvas } from "qrcode.react";
import LabelFormGroup from "../../../components/Inputs/LabelFormGroup";
import SpinnerButton from "../../../components/Buttons/SpinnerButton";
import { useDebouncedCallback } from "use-debounce";
import { pdf } from "@react-pdf/renderer";
import QRCodeCollectionPdf from "../../../exports/qrPdf";
import { formatDate } from "../../../helpers/date";
import moment from "moment";
import { safeString } from "../../../helpers/text";
import { saveAs } from "file-saver";

interface IQrCollectionProps {
  qrCollectionId: string;
}

const QrCollection: React.FunctionComponent = () => {
  const { qrCollectionId } = useParams<IQrCollectionProps>();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<IQrCodeColleciton>();

  const onExport = async () => {
    if (data) {
      const asPdf = pdf(QRCodeCollectionPdf(data));
      const blob = await asPdf.toBlob();
      saveAs(blob, `${formatDate(moment())}${safeString(data.name)}.pdf`);
    }
  };

  const onSubmitCallback = (value = "1") => {
    const values = {
      _id: qrCollectionId,
      name: value,
    };
    axios.put(`/api/classifiers/qrCollection/${qrCollectionId}`, values).catch((err) => {
      console.log("err:", err);
    });
  };

  const onDelete = () => {
    axios
      .delete(`/api/classifiers/qrCollection/${qrCollectionId}`)
      .then(() => {
        history.push(`/qr`);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const onAddQr = () => {
    axios
      .post<IQrCodeColleciton>(`/api/classifiers/qrCollection/${qrCollectionId}/code`)
      .then((response) => {
        setData(response.data);
      });
  };

  const loadData = () => {
    setLoading(true);
    axios
      .get<IQrCodeColleciton>(`/api/classifiers/qrCollection/${qrCollectionId}`)
      .then((result) => {
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("err:", err);
      });
  };

  useEffect(() => {
    loadData();
  }, [qrCollectionId]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <div className="d-flex align-items-center">
              <i className="fas fa-qrcode mt-1" />
              <EditableText value={data ? data.name : ""} onSubmit={onSubmitCallback} />
            </div>
          </>
        }
        button={
          <ActionItems
            elements={[
              {
                icon: "file-export",
                tooltip: t("buttons.exportPDF"),
                type: ActionItemTypes.Button,
                click: onExport,
              },
              {
                icon: "trash",
                tooltip: t("buttons.delete"),
                type: ActionItemTypes.Button,
                click: () =>
                  dispatch(
                    openModal(APPROVE_DIALOG_MODAL, {
                      title: t("modalTitles.approveDialog"),
                      message: t("messages.approveWorkflowDelete", { name: data && data.name }),
                      onSuccessCallback: onDelete,
                    })
                  ),
              },
            ]}
            count={0}
            trigger={Triggers.Click}
            icon={<i className="fas fa-cog" />}
            link={false}
          />
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="pb-2 pt-4">
              <Container>
                <ListGroup>
                  {isLoading ? (
                    <>{/* <LoadingList count={3} /> */}</>
                  ) : (
                    <>
                      {data &&
                        data.codes.map((code) => {
                          return (
                            <QrCodeItem
                              key={code._id}
                              code={code}
                              qrCollectionId={qrCollectionId}
                              callback={(qrCodeCollection) => {
                                setData(qrCodeCollection);
                              }}
                            />
                          );
                        })}
                    </>
                  )}

                  <ListGroupItem
                    style={{ border: 0, marginTop: 16 }}
                    action
                    id="tooltip443412080"
                    onClick={() => onAddQr()}
                  >
                    <Container fluid>
                      <Row className="align-items-center justify-content-center">
                        <Col xs="auto">
                          <i className="fas fa-plus-circle" />
                        </Col>
                        <Col xs="auto">{t("buttons.add")}</Col>
                      </Row>
                    </Container>
                  </ListGroupItem>
                  <UncontrolledTooltip delay={0} target="tooltip443412080">
                    {t("tooltips.addNewQrCode")}
                  </UncontrolledTooltip>
                </ListGroup>
              </Container>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

interface IQrCodeItemParams {
  qrCollectionId: string;
  code: IQrCode;
  callback: (qrCodeCollection: IQrCodeColleciton) => void;
}

const QrCodeItem: React.FunctionComponent<IQrCodeItemParams> = ({
  code,
  qrCollectionId,
  callback,
}) => {
  const { t } = useTranslation();

  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const onDelete = () => {
    setDeleting(true);
    axios
      .delete<IQrCodeColleciton>(`/api/classifiers/qrCollection/${qrCollectionId}/code/${code._id}`)
      .then((response) => {
        callback(response.data);
      })
      .catch(() => {
        setDeleting(false);
      });
  };

  const debounced = useDebouncedCallback((name: string) => {
    setSubmitting(true);
    axios
      .put<IQrCodeColleciton>(`/api/classifiers/qrCollection/${qrCollectionId}/code/${code._id}`, {
        name,
      })
      .then((response) => {
        callback(response.data);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, 1000);

  return (
    <Container fluid>
      <Row className="align-items-center">
        <Col xs="auto">
          <QRCodeCanvas value={code._id} size={52} />
          <QRCodeCanvas id={code._id} value={code._id} size={512} style={{ display: "none" }} />
        </Col>
        <Col>
          <LabelFormGroup
            label={t("fields.name")}
            defaultValue={code.name}
            maxlength={200}
            readOnly={isSubmitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              debounced(e.target.value);
            }}
          />
        </Col>
        <Col xs="auto">
          <SpinnerButton
            className="btn btn-link table-action"
            type="button"
            isLoading={isDeleting}
            onClick={onDelete}
          >
            <span className="btn-inner--icon">
              <i className="fas fa-trash" />
            </span>
          </SpinnerButton>
        </Col>
      </Row>
    </Container>
  );
};

export default QrCollection;
