import { t } from "../helpers/common";

export enum ROLE_PERMISSIONS {
  READ = 2,
  WRITE = 4,
  UPDATE = 8,
  DELETE = 16,
  EXECUTE = 32,
}

interface IRolePermision {
  value: ROLE_PERMISSIONS;
  translationString: string;
}

export const LIST: IRolePermision[] = [
  {
    value: ROLE_PERMISSIONS.READ,
    translationString: t("permissions.read", "Read"),
  },
  {
    value: ROLE_PERMISSIONS.WRITE,
    translationString: t("permissions.write", "Write"),
  },
  {
    value: ROLE_PERMISSIONS.UPDATE,
    translationString: t("permissions.update", "Update"),
  },
  {
    value: ROLE_PERMISSIONS.DELETE,
    translationString: t("permissions.delete", "Delete"),
  },
  {
    value: ROLE_PERMISSIONS.EXECUTE,
    translationString: t("permissions.execute", "Execute"),
  },
];
