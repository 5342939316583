import { t } from "../helpers/common";

export enum workflowQrCodesScans {
  Single = "single",
  All = "all",
  AtLeaseOne = "at_least_one",
  Any = "any",
}

interface IWorkflowQrCodesScan {
  value: workflowQrCodesScans;
  translationString: string;
}

export const SCAN_SINGLE: IWorkflowQrCodesScan = {
  value: workflowQrCodesScans.Single,
  translationString: t("constants.workflowQrCodesScans.single", "Single"),
};

export const SCAN_ALL: IWorkflowQrCodesScan = {
  value: workflowQrCodesScans.All,
  translationString: t("constants.workflowQrCodesScans.all", "All"),
};

export const SCAN_AT_LEAST_ONE: IWorkflowQrCodesScan = {
  value: workflowQrCodesScans.AtLeaseOne,
  translationString: t("constants.workflowQrCodesScans.atLeastOne", "At least one"),
};

export const SCAN_ANY: IWorkflowQrCodesScan = {
  value: workflowQrCodesScans.Any,
  translationString: t("constants.workflowQrCodesScans.any", "Any"),
};

export const LIST: IWorkflowQrCodesScan[] = [SCAN_SINGLE, SCAN_ALL, SCAN_AT_LEAST_ONE, SCAN_ANY];
