import React, { useState, useEffect, ReactNode } from "react";

import { useTranslation } from "react-i18next";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";

interface IProps {
  title: string;
  onRemove: () => void;
}

const Container: React.FunctionComponent<IProps> = ({ children, title, onRemove }) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader>
        <Row
          style={{
            justifyContent: "space-between",
          }}
        >
          <Col xs="auto">{title}</Col>
          <Col xs="auto">
            <Dropdown
              direction="right"
              isOpen={isDropdownOpen}
              toggle={() => {
                setDropdownOpen((e) => !e);
              }}
            >
              <DropdownToggle className="btn-link hover btn-sm mr-0 ml-0">
                <i className="fas fa-ellipsis-h text-muted" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    onRemove();
                  }}
                >
                  <i className="fas fa-trash" />
                  {t("buttons.removeWidget")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="pt-0">{children}</CardBody>
    </Card>
  );
};

export default Container;
