import React, { useState } from "react";

// constants
import * as ROLES from "../constants/roles";
import * as scopes from "../actions/scopes";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { Formik } from "formik";
import providerUserSchema from "../schemas/providerUser";

// redux
import { useDispatch, useSelector } from "react-redux";
import { closeModalById } from "../actions/modals";
import { setPaginationData } from "../actions/common";

// components
import { Modal, Form, Button } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LabelTypeahead from "../components/Inputs/LabelTypeahead";

export default function ProviderUserModal(props) {
  const { modalId, data } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.roles);

  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleteing] = useState(false);

  const onDelete = (_id) => {
    setDeleteing(true);
    axios
      .post("/api/users/delete", { _id })
      .then((res) => {
        dispatch(
          setPaginationData(scopes.USERS, {
            page: 1,
            timestamp: moment().unix(),
          })
        );
        close();
      })
      .catch((err) => {
        setDeleteing(false);
      });
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    axios
      .post("/api/users/update2", values)
      .then((res) => {
        dispatch(
          setPaginationData(scopes.USERS, {
            page: 1,
            timestamp: moment().unix(),
          })
        );
        close();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          firstName: "",
          lastName: "",
          username: "",
          password: "",
          roles: [],
          ...data,
        }}
        validationSchema={providerUserSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">{t("modalTitles.editUser")}</h6>
              <button
                aria-label={t("buttons.close")}
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.fullName")}
                value={values.name}
                disabled
              />
              <LabelFormGroup
                label={t("fields.username")}
                value={values.username}
                disabled
              />
              <LabelTypeahead
                label={t("fields.roles")}
                multiple
                options={roles}
                labelKey={(option) => {
                  if (option.system) {
                    const role = ROLES.LIST.find(
                      (e) => e.value === option.name
                    );
                    return t(role.translationString);
                  } else {
                    return option.name;
                  }
                }}
                onChange={(value) => {
                  setFieldValue(
                    "roles",
                    value.map((role) => role._id)
                  );
                }}
                selected={values.roles.map((value) =>
                  roles.find((role) => role._id === value)
                )}
              />
            </div>
            <div className="modal-footer">
              {values._id && user._id !== values._id ? (
                <SpinnerButton
                  color="danger"
                  onClick={() => onDelete(values._id)}
                  isLoading={deleting}
                  disabled={deleting || submitting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null}
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={submitting}
                disabled={deleting || submitting || !dirty}
              >
                {values._id ? t("buttons.save") : t("buttons.create")}
              </SpinnerButton>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
