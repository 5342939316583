import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

// components
import { FormGroup, Input } from "reactstrap";

export default function LabelFormGroup(props) {
  const { label, placeholder, touched, validationErrors, required, optional, ...rest } = props;

  const { t } = useTranslation();

  const labelId = uniqid();

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(touched && (validationErrors ? true : false));
  }, [touched, validationErrors]);

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={labelId}>
        {label}
        {required ? (
          <span className="text-danger">*</span>
        ) : (
          <>
            {optional ? (
              <span className="text-muted" style={{ fontWeight: 400 }}>
                ({t("texts.optional", "optional")})
              </span>
            ) : null}
          </>
        )}
      </label>
      <Input
        id={labelId}
        placeholder={placeholder ? placeholder : label}
        invalid={invalid}
        {...rest}
      />
      {invalid ? <div className="invalid-feedback">{validationErrors}</div> : null}
    </FormGroup>
  );
}
