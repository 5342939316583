import axios from "axios";

import * as scopes from "./scopes";

// actions
import { setPaginationData, setLoading } from "./common";

import { SET_ACCESS_LOG } from "./types";

const setAccessLog = (payload) => {
  return {
    type: SET_ACCESS_LOG,
    payload,
  };
};

export const getAccessLog = (data) => (dispatch) => {
  dispatch(setLoading(scopes.ACCESS_LOG, true));
  return axios
    .get("/api/workspace/access", { params: data })
    .then((res) => {
      const { docs, ...paginationData } = res.data;
      dispatch(setAccessLog(docs));
      dispatch(setPaginationData(scopes.ACCESS_LOG, paginationData));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    })
    .finally(() => {
      dispatch(setLoading(scopes.ACCESS_LOG, false));
    });
};
