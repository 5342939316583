import { ROLES } from "../constants/roles";

/**
 * Check if user has permission level required to access required module
 * @param  {array} modules
 * @param {string} requiredModule
 * @param {number} requiredPermissionLevel
 */
export function hasModuleAccess(modules, requiredModule, requiredPermissionLevel) {
  const tmpModule = modules.find((module) => module.value === requiredModule);
  if (!tmpModule) {
    return false;
  }

  if (tmpModule.permission < requiredPermissionLevel) {
    return false;
  }

  return true;
}

/**
 * Check if user has required system role
 * @param  {string} userSystemRole
 * @param {string} minimalRequiredRole
 */
export function hasSystemRole(userSystemRoles, minimalRequiredRole) {
  switch (minimalRequiredRole) {
    case ROLES.ADMIN:
      return userSystemRoles.includes(ROLES.ADMIN);
    case ROLES.CONTRIBUTOR:
      return userSystemRoles.includes(ROLES.ADMIN) || userSystemRoles.includes(ROLES.CONTRIBUTOR);
    case ROLES.USER:
      return (
        userSystemRoles.includes(ROLES.ADMIN) ||
        userSystemRoles.includes(ROLES.CONTRIBUTOR) ||
        userSystemRoles.includes(ROLES.USER)
      );
    default:
      return false;
  }
}
