import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import uniqid from "uniqid";

// components
import { FormGroup } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";

export default function LabelTypeahead(props) {
  const { label, placeholder, touched, validationErrors, required, ...rest } = props;

  const { t } = useTranslation();

  const labelId = uniqid();

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(touched && (validationErrors ? true : false));
  }, [touched, validationErrors]);

  return (
    <FormGroup className="mb-0">
      <label className="form-control-label" htmlFor={labelId}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <Typeahead id={labelId} {...rest} emptyLabel={t("placeholders.noMatch")} />
      {invalid ? <div className="invalid-feedback">{validationErrors}</div> : null}
    </FormGroup>
  );
}
