import { t } from "../helpers/common";

export enum workflowTypes {
  Task = "task",
  Procedure = "procedure",
  ScheduledProcedure = "scheduled_procedure",
  Chained = "chained",
  Child = "child",
}

interface IWorkflowType {
  value: workflowTypes;
  translationString: String;
  icon: String;
}

export const WORKFLOW_TASK: IWorkflowType = {
  value: workflowTypes.Task,
  translationString: t("constants.workflowTypes.task", "Task"),
  icon: "fa-clipboard-check",
};

export const WORKFLOW_PROCEDURE: IWorkflowType = {
  value: workflowTypes.Procedure,
  translationString: t("constants.workflowTypes.procedure", "Procedure"),
  icon: "fa-book",
};

export const LIST: IWorkflowType[] = [WORKFLOW_TASK, WORKFLOW_PROCEDURE];
