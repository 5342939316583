import React from "react";

import { t } from "../helpers/common";

import Dashboard from "../views/owner/Dashboard";
import Workspaces from "../views/owner/Workspaces";
// import Users from "views/owner/Users";

export default [
  {
    path: "/",
    component: Dashboard,
    icon: <i className="fas fa-columns text-default" />,
    translationString: t("pages.dashboard", "Dashboard"),
  },
  {
    path: "/workspaces",
    component: Workspaces,
    icon: <i className="fas fa-object-group" />,
    translationString: t("pages.workspaces", "Workspaces"),
  },
  // {
  //   path: "/users",
  //   component: Users,
  //   icon: <i className="fas fa-users text-default" />,
  //   translationString: t("pages.users", "Users"),
  // },
];
