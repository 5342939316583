import React from "react";
import PropTypes from "prop-types";

// constants
import { ROLE_USER } from "../../constants.js";

// utils
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

// components
import { Button, Container, Row, Col, Badge } from "reactstrap";

function SimpleHeader(props) {
  const { t } = useTranslation();
  const { name, pdfIsLoading, onPdfExport, filtersNode, filterCount, toggleFilters } = props;

  const user = useSelector((state) => state.auth.user);

  return (
    <>
      <div className="header header-dark bg-secondary pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="fullcalendar-title h2 text-default d-inline-block mb-0">{name}</h6>
              </Col>
              {user.role === ROLE_USER.value ? null : (
                <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    size="sm"
                    disabled={pdfIsLoading || onPdfExport === undefined}
                    onClick={onPdfExport}
                  >
                    <span className="btn-inner--icon">
                      {pdfIsLoading ? (
                        <i className="fas fa-spinner fa-spin" />
                      ) : (
                        <i className="fas fa-file-export text-default" />
                      )}
                    </span>
                    <span className="btn-inner--text text-default">{t("buttons.exportPdf")}</span>
                  </Button>
                  <Button disabled className="btn-neutral btn-icon" color="default" size="sm">
                    <span className="btn-inner--icon">
                      <i className="fas fa-print" />
                    </span>
                    <span className="btn-inner--text">{t("buttons.print")}</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    size="sm"
                    onClick={toggleFilters}
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-filter text-default" />
                    </span>
                    <span className="btn-inner--text text-default">{t("buttons.filters")}</span>
                    {filterCount > 0 ? (
                      <Badge color="danger" className="badge-sm badge-circle">
                        {filterCount}
                      </Badge>
                    ) : null}
                  </Button>
                </Col>
              )}
            </Row>
            {filtersNode ? (
              <Row>
                <Col>{filtersNode}</Col>
              </Row>
            ) : null}
          </div>
        </Container>
      </div>
    </>
  );
}

SimpleHeader.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onPdfExport: PropTypes.func,
  pdfIsLoading: PropTypes.bool,
  filtersNode: PropTypes.node,
  toggleFilters: PropTypes.func,
  filterCount: PropTypes.number,
};

export default SimpleHeader;
