import * as yup from "yup";
import { workflowTypes } from "../constants/workflowTypes";

const workflowDuplicateSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(50),
});

export type WorkflowDuplicateBase = yup.InferType<typeof workflowDuplicateSchema>;

export default workflowDuplicateSchema;
