import { t } from "../helpers/common";
import i18n from "../i18n";

import { colors } from "./colors";

export enum workflowStepTypes {
  Form = "form",
  Camera = "camera",
  LicensePlate = "license-plate",
  Signature = "signature",
  Qr = "qr",
  Checklist = "checklist",
  Geolocation = "geolocation",
}

interface IWorkflowStepDefinition {
  value: workflowStepTypes;
  translationString: String;
  icon: String;
  color: colors;
}

export const WORKFLOW_FORM: IWorkflowStepDefinition = {
  value: workflowStepTypes.Form,
  translationString: t("constants.workflowSteps.form", "Form"),
  icon: "fa-clipboard-list",
  color: colors.Green,
};

export const WORKFLOW_LICENSE_PLATE: IWorkflowStepDefinition = {
  value: workflowStepTypes.LicensePlate,
  translationString: t("constants.workflowSteps.licensePlate", "License Plate"),
  icon: "fa-car",
  color: colors.Blue,
};

export const SIGNATURE: IWorkflowStepDefinition = {
  value: workflowStepTypes.Signature,
  translationString: t("constants.workflowSteps.signature", "Signature"),
  icon: "fa-signature",
  color: colors.Info,
};

export const QR: IWorkflowStepDefinition = {
  value: workflowStepTypes.Qr,
  translationString: t("constants.workflowSteps.qr", "Qr scan"),
  icon: "fa-qrcode",
  color: colors.Yellow,
};

interface IWorkflowStepType {
  label: string;
  icon: string;
  color: string;
}

type WorklfowSteps = {
  [key in workflowStepTypes]: IWorkflowStepType;
};

export const WORKFLOW_STEPS: WorklfowSteps = {
  [workflowStepTypes.Camera]: {
    label: i18n.t("constants.workflowSteps.camera", "Camera"),
    icon: "fa-camera",
    color: "#E74C3C",
  },
  [workflowStepTypes.Form]: {
    label: i18n.t("constants.workflowSteps.form", "Form"),
    icon: "fa-clipboard-list",
    color: "#5CB85C",
  },
  [workflowStepTypes.LicensePlate]: {
    label: i18n.t("constants.workflowSteps.licensePlate", "License Plate"),
    icon: "fa-car",
    color: "#2C3E50",
  },
  [workflowStepTypes.Signature]: {
    label: i18n.t("constants.workflowSteps.signature", "Signature"),
    icon: "fa-signature",
    color: "#4DBCE9",
  },
  [workflowStepTypes.Qr]: {
    label: i18n.t("constants.workflowSteps.qr", "Qr scan"),
    icon: "fa-qrcode",
    color: "#F7DC6F",
  },
  [workflowStepTypes.Checklist]: {
    label: i18n.t("constants.workflowSteps.checkList", "Checklist"),
    icon: "fa-tasks",
    color: "#F9A43E",
  },
  [workflowStepTypes.Geolocation]: {
    label: i18n.t("constants.workflowSteps.locationCheckIn", "Location check-in"),
    icon: "fa-map-marker-alt",
    color: "#A569BD",
  },
};

// @deprecated
export const LIST: IWorkflowStepDefinition[] = [
  WORKFLOW_FORM,
  {
    value: workflowStepTypes.Camera,
    translationString: t("constants.workflowSteps.camera", "Camera"),
    icon: "fa-camera",
    color: colors.Red,
  },
  WORKFLOW_LICENSE_PLATE,
  SIGNATURE,
  QR,
];
