import React, { useState, useEffect } from "react";

// constants
import * as scopes from "../actions/scopes";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import { Formik } from "formik";
import siteSchema from "../schemas/site";

// Redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";
import { setPaginationData } from "../actions/common";
import { useHistory } from "react-router";

// components
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LoadingOverlay from "../components/LoadingOverlay";
import { siteRoutes } from "../helpers/apiRoutes";
import { ISite } from "../types/Site";

interface IProps {
  modalId: string;
  siteId?: string;
  onSuccess?: () => void;
}

export default function SiteModal({ modalId, siteId, onSuccess }: IProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [deleting, setDeleteing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [site, setSite] = useState<Omit<ISite, "workspaceId">>({
    _id: "",
    name: "",
    address: "",
    contactName: "",
    contactNumber: "",
    contactEmail: "",
    workflows: [],
  });

  useEffect(() => {
    if (siteId) {
      setIsLoading(true);
      axios
        .get(siteRoutes.get.site(siteId))
        .then((res) => {
          setSite(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setIsLoading(false);
        });
    }
  }, [siteId]);

  const onDelete = (_id: string) => {
    setDeleteing(true);
    axios
      .post("/api/sites/delete", { _id })
      .then((res) => {
        history.push("/sites");
        close();
      })
      .catch((err) => {
        setDeleteing(false);
      });
  };

  const onSubmit = (values: Omit<ISite, "workspaceId">) => {
    setSubmitting(true);
    axios
      .post(values._id ? "/api/sites/update" : "/api/sites/create", values)
      .then((res) => {
        if (onSuccess) onSuccess();
        close();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };
  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <LoadingOverlay isLoading={isLoading}>
        <Formik
          initialValues={site}
          enableReinitialize
          validationSchema={siteSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            dirty,
          }) => (
            <Form role="form" onSubmit={handleSubmit}>
              <div className="modal-header">
                <h6 className="modal-title">
                  {values._id ? t("modalTitles.editSite") : t("modalTitles.createSite")}
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={close}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <LabelFormGroup
                  label={t("fields.name")}
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  touched={touched.name}
                  validationErrors={errors.name}
                />
                <LabelFormGroup
                  label={t("fields.address")}
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  touched={touched.address}
                  validationErrors={errors.address}
                />
                <LabelFormGroup
                  label={t("fields.contactNumber")}
                  name="contactNumber"
                  value={values.contactNumber}
                  onChange={handleChange}
                  touched={touched.contactNumber}
                  validationErrors={errors.contactNumber}
                />
                <LabelFormGroup
                  label={t("fields.contactName")}
                  name="contactName"
                  value={values.contactName}
                  onChange={handleChange}
                  touched={touched.contactName}
                  validationErrors={errors.contactName}
                />
                <LabelFormGroup
                  label={t("fields.contactEmail")}
                  name="contactEmail"
                  value={values.contactEmail}
                  onChange={handleChange}
                  touched={touched.contactEmail}
                  validationErrors={errors.contactEmail}
                />
              </div>

              <div className="modal-footer">
                {values._id ? (
                  <SpinnerButton
                    color="danger"
                    onClick={() => onDelete(values._id)}
                    isLoading={deleting}
                    disabled={deleting || submitting}
                  >
                    {t("buttons.delete")}
                  </SpinnerButton>
                ) : null}
                <SpinnerButton
                  className="ml-auto"
                  color="primary"
                  type="submit"
                  isLoading={submitting}
                  disabled={deleting || submitting || !dirty}
                >
                  {values._id ? t("buttons.save") : t("buttons.create")}
                </SpinnerButton>
                <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                  {t("buttons.close")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </LoadingOverlay>
    </Modal>
  );
}
