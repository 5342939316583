import React, { useState, useEffect } from "react";

import { closeModalById } from "../actions/modals";

import axios from "axios";

import { IWorkflow } from "../types/Workflow";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as WIDGETS from "../constants/widgetsSiteDashboard";
import { siteRoutes } from "../helpers/apiRoutes";

import { Modal, Button, Form } from "reactstrap";

import LabelSelectFormGroup from "../components/Inputs/LabelSelectFormGroup";
import SpinnerButton from "../components/Buttons/SpinnerButton";

interface IProps {
  modalId: string;
  siteId: string;
  onSubmitCallback(): void;
}

export default function WorkflowCountModal(props: IProps) {
  const { modalId, siteId, onSubmitCallback } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    axios
      .get(siteRoutes.get.workflowsAll(siteId))
      .then((res) => {
        console.log("res:", res);

        setWorkflows(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const onSubmit = (values: any) => {
    setIsSubmitting(true);
    axios
      .post(siteRoutes.post.workflowCount(siteId), values)
      .then((res) => {
        onSubmitCallback();
        close();
      })
      .catch((err) => {
        setIsSubmitting(false);
      });
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workflowCount")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Formik
        initialValues={{
          _id: null,
          type: WIDGETS.WidgetTypes.workflowCount,
          workflowId: "",
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleChange, handleSubmit, dirty }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-body">
              <LabelSelectFormGroup
                label={t("fields.workflows")}
                name="workflowId"
                value={values.workflowId}
                onChange={handleChange}
                options={workflows.map((workflow) => ({
                  value: workflow._id,
                  name: workflow.name,
                }))}
              />
            </div>
            <div className="modal-footer">
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={isSubmitting}
                disabled={isSubmitting || !dirty}
              >
                {t("buttons.create")}
              </SpinnerButton>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
