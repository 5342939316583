import * as yup from "yup";

const profilePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(6),
  newPassword: yup
    .string()
    .required()
    .min(6),
  newPasswordRepeat: yup
    .string()
    .required()
    .oneOf([yup.ref("newPassword"), ''], "Passwords must match")
});

export default profilePasswordSchema;