import React, { useState, useEffect } from "react";

// types
import { IWorkflow } from "../types/Workflow";
import { ISite } from "../types/Site";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";

// redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";

import { siteRoutes, workflowRoutes } from "../helpers/apiRoutes";

import { Button, Modal, UncontrolledTooltip, Table } from "reactstrap";
import Skeleton from "react-loading-skeleton";

// components
import LabelSelectFormGroup from "../components/Inputs/LabelSelectFormGroup";

interface ISiteWorkflowsModalProps {
  modalId: string;
  siteId: string;
  onChange: () => void;
}

export default function SiteWorkflowsModal(props: ISiteWorkflowsModalProps) {
  const { modalId, siteId, onChange } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [site, setSite] = useState<ISite>();
  const [selectedWorkflows, setSelectedWorkflows] = useState<IWorkflow[]>([]);
  const [availableWorkflows, setAvailableWorkflows] = useState<IWorkflow[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(siteRoutes.get.site(siteId))
      .then((res) => {
        setSite(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    axios
      .get(workflowRoutes.get.workflows)
      .then((res) => {
        setWorkflows(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (site) {
      setSelectedWorkflows(
        workflows
          .filter((workflow) => site.workflows.includes(workflow._id))
          .map((workflow) => workflow)
      );
    } else {
      setSelectedWorkflows([]);
    }
  }, [site, workflows]);

  useEffect(() => {
    setAvailableWorkflows(
      workflows.filter(
        (workflow) =>
          selectedWorkflows.findIndex(
            (selectedWorkflow) => selectedWorkflow._id === workflow._id
          ) === -1
      )
    );
  }, [selectedWorkflows]);

  const handleRemove = (workflowId: string) => {
    // TODO: add delete loader
    if (site) {
      axios
        .post(siteRoutes.delete.workflow(site._id), { workflowId })
        .then((res) => {
          setSelectedWorkflows(
            workflows
              .filter((workflow) => res.data.includes(workflow._id))
              .map((workflow) => workflow)
          );
          onChange();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleChange = (e: { target: { value: string } }) => {
    // TODO: add submitting loader
    if (site) {
      axios
        .post(siteRoutes.post.workflow(site._id), { workflowId: e.target.value })
        .then((res) => {
          setSelectedWorkflows(
            workflows
              .filter((workflow) => res.data.includes(workflow._id))
              .map((workflow) => workflow)
          );
          onChange();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.siteWorkflows")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Table className="align-items-center table-flush table-hover" responsive striped>
          <thead className="thead-light">
            <tr>
              <th>{t("fields.workflows")}</th>
              <th style={{ width: 1 }} />
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={2} className="p-0">
                  <Skeleton height={54.4} />
                </td>
              </tr>
            ) : (
              selectedWorkflows.map((workflow) => {
                return (
                  <tr key={workflow._id}>
                    <td>{workflow ? <b>{workflow.name}</b> : <i>{t("texts.undefined")}</i>}</td>
                    <td className="table-action">
                      <button
                        id={`workflowTooltip${workflow._id}`}
                        type="button"
                        className="btn btn-link table-action"
                        onClick={() => handleRemove(workflow._id)}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                      <UncontrolledTooltip delay={0} target={`workflowTooltip${workflow._id}`}>
                        {t("tooltips.removeWorkflow")}
                      </UncontrolledTooltip>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        <LabelSelectFormGroup
          label={t("fields.workflow")}
          name="workflow"
          value={site}
          onChange={handleChange}
          options={availableWorkflows}
          getOptionValue={(workflow: IWorkflow) => workflow._id}
        />
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
