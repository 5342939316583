import axios from "axios";

import * as scopes from "./scopes";

// actions
import { setPaginationData, setLoading } from "./common";

import { SET_USERS } from "./types";

const setUsers = (payload) => {
  return {
    type: SET_USERS,
    payload,
  };
};

export const getUsers = (data) => (dispatch) => {
  dispatch(setLoading(scopes.USERS, true));
  return axios
    .post("/api/users/get", data)
    .then((res) => {
      const { docs, ...paginationData } = res.data;
      dispatch(setUsers(docs));
      dispatch(setPaginationData(scopes.USERS, paginationData));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    })
    .finally(() => {
      dispatch(setLoading(scopes.USERS, false));
    });
};

export const getAllUsers = (data) => (dispatch) => {
  dispatch(setLoading(scopes.USERS, true));
  return axios
    .post("/api/users/getAll", data)
    .then((res) => {
      const { docs, ...paginationData } = res.data;
      dispatch(setUsers(docs));
      dispatch(setPaginationData(scopes.USERS, paginationData));
    })
    .catch((err) => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    })
    .finally(() => {
      dispatch(setLoading(scopes.USERS, false));
    });
};
