import React, { useState, useEffect } from "react";
import * as Constants from "../constants.js";

import * as MODULES from "../constants/modules";

// utils
import axios from "axios";
import * as _ from "lodash";

// redux
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../actions/authentication";

import { setRoles } from "../actions/classifiers/roles";
import { setEventTypes } from "../actions/classifiers/eventTypes";
import { setTransportTypes } from "../actions/classifiers/transportTypes";

// components
import { Progress, Container, Col, Row } from "reactstrap";

function DataLoader(props) {
  const { children } = props;

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState({
    userData: Constants.DATA_LOADING,
    roles: Constants.DATA_LOADING,
  });

  useEffect(() => {
    axios
      .get("api/auth/me")
      .then((res) => {
        dispatch(setUserData(res.data));
      })
      .catch((err) => {
        setLoading((o) => ({ ...o, userData: Constants.DATA_FAILED }));
      });
  }, [dispatch]);

  useEffect(() => {
    if (user._id) {
      if (user.owner) {
        setLoading((o) => ({ userData: Constants.DATA_SUCCESSFUL }));
      } else {
        axios
          .get("api/classifiers/roles")
          .then((res) => {
            dispatch(setRoles(res.data));
            setLoading((o) => ({ ...o, roles: Constants.DATA_SUCCESSFUL }));
          })
          .catch((err) => {
            setLoading((o) => ({ ...o, roles: Constants.DATA_FAILED }));
          });
      }
    }
  }, [user]);

  useEffect(() => {
    if (loading.roles === Constants.DATA_SUCCESSFUL && user._id && !user.owner) {
      user.modules.forEach((module) => {
        setLoading((o) => ({ ...o, [module.value]: Constants.DATA_LOADING }));
        switch (module.value) {
          case MODULES.EVENT_REGISTRY:
            eventRegistry();
            break;
          case MODULES.TRANSPORT_REGISTRY:
            transportRegsitry();
            break;
          // Modules with no data to load
          case MODULES.VISITOR_REGISTRY:
          case MODULES.CALENDAR:
          case MODULES.WORKFLOWS:
            setLoading((o) => ({
              ...o,
              [module.value]: Constants.DATA_SUCCESSFUL,
            }));
            break;
          default:
            console.error("undefined module:", module);
            setLoading((o) => ({
              ...o,
              [module.value]: Constants.DATA_FAILED,
            }));
            break;
        }
      });

      setLoading((o) => ({ ...o, userData: Constants.DATA_SUCCESSFUL }));
    }
  }, [loading.roles, user]);

  const eventRegistry = async () => {
    axios
      .post("/api/classifiers/eventTypes/get")
      .then((res) => {
        setLoading((o) => ({
          ...o,
          [MODULES.EVENT_REGISTRY]: Constants.DATA_SUCCESSFUL,
        }));
        dispatch(setEventTypes(res.data));
      })
      .catch((err) => {
        setLoading((o) => ({
          ...o,
          [MODULES.EVENT_REGISTRY]: Constants.DATA_FAILED,
        }));
      });
  };

  const transportRegsitry = async () => {
    axios
      .post("api/classifiers/transportTypes/get")
      .then((res) => {
        console.log("transportTypes:", res);
        dispatch(setTransportTypes(res.data));
        setLoading((o) => ({
          ...o,
          [MODULES.TRANSPORT_REGISTRY]: Constants.DATA_SUCCESSFUL,
        }));
      })
      .catch((err) => {
        setLoading((o) => ({
          ...o,
          [MODULES.TRANSPORT_REGISTRY]: Constants.DATA_FAILED,
        }));
      });
  };

  useEffect(() => {
    if (
      _.size(
        _.filter(loading, (o) => {
          return o === Constants.DATA_SUCCESSFUL;
        })
      ) === _.size(loading)
    ) {
      setTimeout(function() {
        setReady(true);
      }, 500);
    }
  }, [loading]);

  return (
    <>
      {ready ? (
        children
      ) : (
        <Container>
          <Col
            style={{
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Row xs={12}>
              <Progress multi={true} style={{ width: "100%", borderRadius: 0 }}>
                <Progress
                  bar
                  value={_.size(
                    _.filter(loading, (o) => {
                      return o === Constants.DATA_SUCCESSFUL;
                    })
                  )}
                  max={_.size(user.modules) + user.owner ? 1 : 2}
                />
                <Progress
                  bar
                  color="danger"
                  value={_.size(
                    _.filter(loading, (o) => {
                      return o === Constants.DATA_FAILED;
                    })
                  )}
                  max={_.size(user.modules) + user.owner ? 1 : 2}
                />
              </Progress>
            </Row>
          </Col>
        </Container>
      )}
    </>
  );
}

export default DataLoader;
