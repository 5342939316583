import { t } from "../helpers/common";

export enum fields {
  Input = "input_text",
  InputNumber = "input_number",
  Textarea = "textarea",
  Checkbox = "checkbox",
}

interface IFormFieldListItem {
  value: fields;
  translationString: string;
  disabled?: boolean;
  getDefaults: IGetDefaults;
}

interface IGetDefaults {
  (): FormField;
}

interface IFormFieldBase {
  _id?: string;
  workflow?: string;
  name: string;
  required: boolean;
  order: number;
  orderId?: number;
}

export interface IFormFieldInput extends IFormFieldBase {
  type: fields.Input;
  minLength: number;
  maxLength: number;
  value?: string;
}

export interface IFormFieldInputNumber extends IFormFieldBase {
  type: fields.InputNumber;
  min: number;
  max: number;
  value?: number;
}

export interface IFormFieldTextArea extends IFormFieldBase {
  type: fields.Textarea;
  minLength: number;
  maxLength: number;
  value?: string;
}

export interface IFormFieldCheckbox extends IFormFieldBase {
  type: fields.Checkbox;
  value?: boolean;
}

export type FormField =
  | IFormFieldInput
  | IFormFieldInputNumber
  | IFormFieldTextArea
  | IFormFieldCheckbox;

export const LIST: IFormFieldListItem[] = [
  {
    value: fields.Input,
    translationString: t("constants.formFields.input", "Input text"),
    getDefaults: (): IFormFieldInput => {
      return {
        type: fields.Input,
        name: "",
        minLength: 0,
        maxLength: 50,
        required: true,
        order: 0,
      };
    },
  },
  {
    value: fields.InputNumber,
    translationString: t("constants.formFields.inputNumber", "Input number"),
    getDefaults: (): IFormFieldInputNumber => {
      return {
        type: fields.InputNumber,
        name: "",
        min: 0,
        max: 50,
        required: true,
        order: 0,
      };
    },
  },
  {
    value: fields.Textarea,
    translationString: t("constants.formFields.textarea", "Textarea"),
    getDefaults: (): IFormFieldTextArea => {
      return {
        type: fields.Textarea,
        name: "",
        minLength: 0,
        maxLength: 50,
        required: true,
        order: 0,
      };
    },
  },
  {
    value: fields.Checkbox,
    translationString: t("constants.formFields.checkbox", "checkbox"),
    getDefaults: (): IFormFieldCheckbox => {
      return {
        type: fields.Checkbox,
        name: "",
        required: true,
        order: 0,
      };
    },
  },
];
