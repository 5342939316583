import i18n from "../i18n";

export enum WorkflowExecutionStatus {
  inProgress = "inProgress",
  completed = "completed",
  aborted = "aborted",
}

export interface IWorkflowExecutionStatus {
  label: string;
  color?:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

type IWorklfowExecutionStatuses = {
  [key in WorkflowExecutionStatus]: IWorkflowExecutionStatus;
};

export const WORKFLOW_EXECUTION_STATUSES: IWorklfowExecutionStatuses = {
  [WorkflowExecutionStatus.inProgress]: {
    label: i18n.t("constants.workflowExecution.inProgress", "In progress"),
    color: "primary",
  },
  [WorkflowExecutionStatus.completed]: {
    label: i18n.t("constants.workflowExecution.completed", "Completed"),
    color: "success",
  },
  [WorkflowExecutionStatus.aborted]: {
    label: i18n.t("constants.workflowExecution.aborted", "Aborted"),
    color: "warning",
  },
};
