import * as types from "../../actions/types";

const initialLoadingState = true;

export const loadingReducerFor = prefix => {
  const loadingReducer = (state = initialLoadingState, action) => {
    const { type, payload } = action;
    switch (type) {
      case prefix + types.SET_LOADING:
        return payload;
      default:
        return state;
    }
  };
  return loadingReducer;
};
