export enum VISITOR_STATUS {
  LEFT = "left",
  ARRIVED = "arrived",
  SCHEDULED = "scheduled",
}

export interface IVisitorBase {
  _id?: string;
  siteId?: string;
  workspaceId?: string;
  firstName: string;
  lastName: string;
  notes?: string;
  scheduledArrival?: Date;
  arrived?: Date;
  left?: Date;
}

interface IVisitorScheduled extends IVisitorBase {
  status: VISITOR_STATUS.SCHEDULED;
  scheduledArrival: Date;
}

interface IVisitorArrived extends IVisitorBase {
  status: VISITOR_STATUS.ARRIVED;
  arrived: Date;
}

interface IVisitorLeft extends IVisitorBase {
  status: VISITOR_STATUS.LEFT;
  arrived: Date;
  left: Date;
}

export type Visitor = IVisitorScheduled | IVisitorArrived | IVisitorLeft;

export interface IVisitorFilters {
  arrivedFrom?: Date;
  arrivedTo?: Date;
  leftFrom?: Date;
  leftTo?: Date;
  status?: VISITOR_STATUS;
}
