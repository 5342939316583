import { t } from "../helpers/common";

export enum ROLES {
  OWNER = "owner",
  ADMIN = "admin",
  CONTRIBUTOR = "contributor",
  USER = "user",
}

interface ISystemRole {
  value: ROLES;
  translationString: string;
  tooltipString: string;
}

export const LIST: ISystemRole[] = [
  {
    value: ROLES.OWNER,
    translationString: t("constants.roles.owner"),
    tooltipString: "",
  },
  {
    value: ROLES.ADMIN,
    translationString: t("constants.roles.admin"),
    tooltipString: t("tooltips.adminRole"),
  },
  {
    value: ROLES.CONTRIBUTOR,
    translationString: t("constants.roles.contributor"),
    tooltipString: t("tooltips.contributorRole"),
  },
  {
    value: ROLES.USER,
    translationString: t("constants.roles.user"),
    tooltipString: t("tooltips.userRole"),
  },
];
