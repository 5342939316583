import { OPEN_MODAL, CLOSE_MODAL, CLOSE_MODAL_BY_ID } from "../actions/types";

const initialState = {
  modalId: null,
  modalName: null,
  modalProps: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        modalId: action.payload.modalId,
        modalName: action.payload.modalName,
        modalProps: action.payload.modalProps
      };
    case CLOSE_MODAL_BY_ID:
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
}
