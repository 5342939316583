import { DistributiveOmit } from "../types/core";

// utils
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { Modals } from "../types/Modal";

const initialState: Modals[] = [];

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    clearModals: () => initialState,
    openModal: (state, { payload }: { payload: DistributiveOmit<Modals, "id"> }) => {
      state.push({ id: uuidv4(), ...payload });
    },
    closeModalById: (state, { payload }: { payload: string }) => {
      state.splice(
        state.findIndex((modal) => modal.id === payload),
        1
      );
    },
  },
});

export const { clearModals, openModal, closeModalById } = modalsSlice.actions;

export default modalsSlice.reducer;
