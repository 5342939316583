import * as yup from "yup";
import moment from "moment";

const eventSchema = () => {
  return yup.object().shape({
    eventType: yup.string().required(),
    occured: yup
      .date("validation.invalidDate")
      .required("validation.dateRequired")
      .max(moment().add(1, "s")),
    description: yup
      .string()
      .required("validation.fieldRequired")
      .min(3)
      .max(1000),
  });
};

export default eventSchema;
