import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Container } from "reactstrap";
import SimpleHeader from "../../components/Headers/SimpleHeader";

import axios from "axios";
import { io } from "socket.io-client";

import { Icon, DivIcon } from "leaflet";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";

import LoadingOverlay from "../../components/LoadingOverlay";

import { removeTrailingSlash } from "../../helpers/text";

import Pin from "../../assets/img/icons/pin.png";
import { ILocationCoords } from "../../types/common";
import moment from "moment";

const icon = new Icon({
  iconUrl: Pin,
  iconSize: [37, 104],
});

interface IUserLocation {
  _id: string;
  name: string;
  isNew?: boolean;
  lastKnownLocation: ILocationCoords;
}

const CentralOffice = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<IUserLocation[]>([]);

  useEffect(() => {
    axios
      .get<IUserLocation[]>("/api/location/users")
      .then((res) => {
        setUsers(res.data);
        const socket = io(process.env.REACT_APP_API_URL as string);

        socket.on("connect", () => {
          console.log("connect:", socket.id);
        });
        socket.on("connect_error", (err) => {
          console.log("connection error:", err.message);

          setTimeout(() => socket.connect(), 5000);
        });
        socket.on("location_update", (data: IUserLocation) => {
          console.log("data:", data);

          if (data.isNew) {
            setUsers((e) => [...e, data]);
          } else {
            setUsers((e) => e.map((user) => (user._id === data._id ? data : user)));
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    //  socket.on('disconnect',()=>setTime('server disconnected'))
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-map-marked-alt mr-1" />
            {t("pages.centralOffice")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Map
          center={[
            56.981506309089326,
            24.393194314764013,
            // users[0] ? users[0].lastKnownLocation.latitude : 56.981506309089326,
            // users[0] ? users[0].lastKnownLocation.longitude : 24.393194314764013,
          ]}
          zoom={8}
          scrollWheelZoom={false}
          style={{ width: "100%", height: "calc(100vh - 250px)" }}
        >
          <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" />
          {/* <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
          {users.map((user) => {
            const userIcon = new DivIcon({
              iconSize: [16, 16],
              className: "user-marker",
            });

            return (
              <Marker
                key={user._id}
                position={[user.lastKnownLocation.latitude, user.lastKnownLocation.longitude]}
                // @ts-ignore
                icon={userIcon}
              >
                <Popup>
                  Name: {user.name}
                  <br />
                  Speed: {user.lastKnownLocation.speed}
                  <br />
                  {user.lastKnownLocation.timestamp ? (
                    <>LastUpdate: {moment(user.lastKnownLocation.timestamp).format()}</>
                  ) : null}
                </Popup>
              </Marker>
            );
          })}
        </Map>
      </Container>
    </>
  );
};

export default CentralOffice;
