import React from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";

// utils
import { useTranslation } from "react-i18next";

// components
import { NavItem, NavLink } from "reactstrap";

export default function Item(props) {
  const { route, onClick, ...rest } = props;

  const { t } = useTranslation();

  return (
    <NavItem>
        <NavLink {...rest} to={route.path} exact onClick={onClick} tag={NavLinkRRD}>
          {route.icon}
          <span className="nav-link-text">{t(route.translationString)}</span>
        </NavLink>
    </NavItem>
  );
}
