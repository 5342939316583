import React, { useState } from "react";

// constants
import * as scopes from "../actions/scopes";
import * as WORKFLOW_TYPES from "../constants/workflowTypes";

// utils
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import axios from "axios";
import moment from "moment";
import workflowSchema from "../schemas/workflow";

// Redux
import { useDispatch } from "react-redux";
import { setPaginationData } from "../actions/common";
import { closeModalById } from "../actions/modals";

// components
import { Formik } from "formik";
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

export default function WorkflowModal(props) {
  const { modalId, data } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleteing] = useState(false);

  const onDelete = (_id) => {
    setDeleteing(true);
    axios
      .delete(`/api/workflows/${_id}`)
      .then(() => {
        dispatch(setPaginationData(scopes.WORKFLOWS, { timestamp: moment().unix() }));
        close();
      })
      .catch((err) => {
        console.log("err:", err);
        setDeleteing(false);
      });
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    if (values._id) {
      axios
        .put(`/api/workflows/${values._id}`, values)
        .then(() => {
          dispatch(
            setPaginationData(scopes.WORKFLOWS, {
              timestamp: moment().unix(),
            })
          );
          close();
        })
        .catch((err) => {
          setSubmitting(false);
        });
    } else {
      axios
        .post("/api/workflows", values)
        .then((response) => {
          history.push(`/workflows/${response.data._id}`);
          close();
        })
        .catch((err) => {
          setSubmitting(false);
        });
    }
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          name: "",
          type: WORKFLOW_TYPES.workflowTypes.Procedure,
          ...data,
        }}
        validationSchema={workflowSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id ? t("modalTitles.editWorkflow") : t("modalTitles.newWorkflow")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
            </div>
            <div className="modal-footer">
              {values._id ? (
                <SpinnerButton
                  isLoading={deleting}
                  color="danger"
                  onClick={() => onDelete(values._id)}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null}

              <SpinnerButton
                isLoading={submitting}
                type="submit"
                className="ml-auto"
                color="primary"
                disabled={!dirty}
              >
                {values._id ? t("buttons.save") : t("buttons.create")}
              </SpinnerButton>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
