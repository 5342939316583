import React, { useState } from "react";

// interfaces
import { FormikErrors, FormikTouched } from "formik";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { FormField, fields } from "../../../../../constants/formFields";

// utils
import axios from "axios";

import { ListGroupItem, Container, Row, Col } from "reactstrap";

import InputItem from "./InputItem";
import InputNumberItem from "./InputNumberItem";
import TextareaItem from "./TextareaItem";
import SpinnerButton from "../../../../../components/Buttons/SpinnerButton";
import CheckboxItem from "./CheckboxItem";

interface IItemProps {
  dragHandleProps?: DraggableProvidedDragHandleProps;
  values: FormField;
  index: number;
  touched: FormikTouched<FormField>;
  errors: string | FormikErrors<FormField>;
  setFieldValue: ISetFieldValue;
  onRemove: Function;
}

interface ISetFieldValue {
  (field: string, value: any, shouldValidate?: boolean | undefined): void;
}

export default function Item({
  dragHandleProps,
  values,
  index,
  setFieldValue,
  touched,
  errors,
  onRemove,
}: IItemProps) {
  const [isDleting, setDeleting] = useState(false);

  const onDelete = () => {
    if (values._id && values.workflow) {
      setDeleting(true);
      axios
        .delete(`/api/workflows/${values.workflow}/form-field/${values._id}`)
        .then(() => {
          onRemove();
        })
        .catch((err) => {
          console.log("err:", err);
          setDeleting(false);
        });
    } else {
      onRemove();
    }
  };

  const getCorespondingItem = () => {
    switch (values.type) {
      case fields.Input:
        return (
          <InputItem
            values={values}
            index={index}
            touched={touched}
            errors={typeof errors !== "string" ? errors : {}}
            setFieldValue={setFieldValue}
          />
        );
      case fields.InputNumber:
        return (
          <InputNumberItem
            values={values}
            index={index}
            touched={touched}
            errors={typeof errors !== "string" ? errors : {}}
            setFieldValue={setFieldValue}
          />
        );
      case fields.Textarea:
        return (
          <TextareaItem
            values={values}
            index={index}
            touched={touched}
            errors={typeof errors !== "string" ? errors : {}}
            setFieldValue={setFieldValue}
          />
        );
      case fields.Checkbox:
        return (
          <CheckboxItem
            values={values}
            index={index}
            touched={touched}
            errors={typeof errors !== "string" ? errors : {}}
            setFieldValue={setFieldValue}
          />
        );
      default:
        return console.error(`${values} is added but won't render`);
    }
  };

  return (
    <ListGroupItem>
      <Container fluid>
        {typeof errors === "string" && touched ? (
          <Row className="justify-content-center">
            <Col xs="auto">
              <div className="invalid-feedback" style={{ display: "block" }}>
                {errors}
              </div>
            </Col>
          </Row>
        ) : null}
        <Row className="align-items-center">
          <Col xs="auto" {...dragHandleProps}>
            <i className="fas fa-grip-vertical" style={{ paddingTop: 15, paddingBottom: 15 }} />
          </Col>
          <Col>
            <>{getCorespondingItem()}</>
          </Col>
          <Col xs="auto">
            <SpinnerButton
              className="btn btn-link table-action"
              type="button"
              isLoading={isDleting}
              onClick={onDelete}
            >
              <span className="btn-inner--icon">
                <i className="fas fa-trash" />
              </span>
            </SpinnerButton>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>
  );
}
