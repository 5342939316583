import { SET_WORKFLOWS } from "../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_WORKFLOWS:
      return action.payload;
    default:
      return state;
  }
}
