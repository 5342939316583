import * as yup from "yup";
import { workflowTypes } from "../constants/workflowTypes";

const workflowSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(50),
  type: yup
    .string()
    .required()
    .oneOf(Object.values(workflowTypes)),
});

export type WorkflowBase = yup.InferType<typeof workflowSchema>;

export default workflowSchema;
