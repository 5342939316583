import React, { useState } from "react";

import * as scopes from "../actions/scopes";
import { MODULE_LIST } from "../constants/modules";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

// redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";
import { setPaginationData } from "../actions/common";

// components
import { Modal, Button, Row, Col } from "reactstrap";

export default function WorkspaceModal(props) {
  const { modalId, data } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [value, setValue] = useState(data.modules);

  const onChange = (checked, module) => {
    var newValue = [];
    if (checked) {
      newValue = [...value, module];
    } else {
      newValue = value.filter((i) => i !== module);
    }
    axios
      .post("/api/workspaces/modules", {
        _id: data._id,
        modules: newValue,
      })
      .then(() => {
        // TODO: add success notification
        setValue(newValue);
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  const close = () => {
    dispatch(
      setPaginationData(scopes.WORKSPACES, { timestamp: moment().unix() })
    );
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workspaceSettings")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        {MODULE_LIST.map((module) => (
          <Row key={module.value}>
            <Col xs={6}>{t(module.translationString)}</Col>
            <Col xs={6} style={{ textAlign: "right" }}>
              <label className="custom-toggle mr-1">
                <input
                  type="checkbox"
                  checked={value.includes(module.value)}
                  onChange={(event) => {
                    onChange(event.target.checked, module.value);
                  }}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Col>
          </Row>
        ))}
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
