import axios from "axios";
import React, { useState, useEffect } from "react";

// utils
import { useTranslation } from "react-i18next";
import { formatDatetime } from "../../helpers/date";

import WidgetContainer from "./WidgetContainer";

import * as ICONS from "../../constants/userActionsIcons";
import { UncontrolledTooltip } from "reactstrap";
import { usersRoutes } from "../../helpers/apiRoutes";

// components

interface userActions {
  actions:[{
    _id: string,
    created: string,
    type: string,
    user:{
      name: string,
    }
  }],
  userCount: string,
}

interface IProps {
  widgetId: string;
  onDelete: (id: string) => any;
}

export default function UserActions({ widgetId, onDelete }: IProps) {
  const [userActions, setUserActions] = useState<userActions>();

  const { t } = useTranslation();

  useEffect(() => {
    axios
    .get(usersRoutes.get.actions)
    .then((res) => {
      setUserActions(res.data);
    })
    .catch((err) => {
      console.error(err);
    });
  }, []);

  const getCorrespondingIcon = (action: {type: string, _id: string}) => {
    const icon = ICONS.LIST.find((e) => e.value === action.type);
    if(icon){
      return(
        <div className="col-1 ml-2 align-self-center">
          <span className={`timeline-step position-static badge-${icon.color}`}  id={"tooltip_" + action._id}><i className={`fas ${icon.icon}`}></i></span> 
          <UncontrolledTooltip
            delay={0}
            target={"tooltip_" + action._id}
          >
            {t(icon.tooltipTranslationString)}
          </UncontrolledTooltip>
        </div>
      );
    }
  }

  return (
      <WidgetContainer 
        id={widgetId}
        widgetElements={
          <div>
            <div className="d-flex flex-column align-items-baseline">
              <h3 className="m-0 text-truncate">
              {t("widgetTitles.userActions")}
              </h3>
              <h5 className="text-sm text-truncate">{userActions ? userActions.userCount : null} {t("texts.totalUsers")}</h5>
            </div>
            {userActions ? (userActions.actions.map((action, i) => 
                <div className="d-flex pb-3" key={i}>
                {getCorrespondingIcon(action)}
                <div className="col">
                  <small className="text-muted font-weight-bold">{formatDatetime(action.created)}</small>
                  <h5 className="mt-1 mb-0 me-auto">{action.user.name}</h5>
                </div>
                </div>
            )) : null}
          </div>
        }
        onRemove={onDelete}
      />
  );
}
