import * as yup from "yup";

const qrCodeCollectionSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(200),
  count: yup.number().max(2000),
});

export default qrCodeCollectionSchema;
