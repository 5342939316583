import axios from "axios";
import {
  SET_EVENT_TYPES,
  PREPEND_EVENT_TYPE,
  REPLACE_EVENT_TYPE,
  REMOVE_EVENT_TYPE
} from "../types";

import { closeModal } from "../../actions/modals";
import { EVENT_TYPES_MODAL } from "../../modals/types";

export const setEventTypes = eventTypes => {
  return {
    type: SET_EVENT_TYPES,
    eventTypes
  };
};

export const getEventTypes = () => dispatch => {
  axios
    .post("/api/classifiers/eventTypes/get")
    .then(res => {
      dispatch(setEventTypes(res.data));
    })
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

export const createEventType = data => dispatch => {
  return axios
    .post("/api/classifiers/eventTypes/create", data)
    .then(res => {
      dispatch(closeModal(EVENT_TYPES_MODAL));
      dispatch(prependEventType(res.data));
    })
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

const prependEventType = eventType => {
  return {
    type: PREPEND_EVENT_TYPE,
    payload: eventType
  };
};

const replaceEventType = eventType => {
  return {
    type: REPLACE_EVENT_TYPE,
    payload: eventType
  };
};

export const updateEventType = data => dispatch => {
  return axios
    .post("/api/classifiers/eventTypes/update", data)
    .then(res => {
      dispatch(closeModal(EVENT_TYPES_MODAL));
      dispatch(replaceEventType(res.data));
    })
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

const removeEventType = _id => {
  return {
    type: REMOVE_EVENT_TYPE,
    payload: _id
  };
};

export const deleteEventType = _id => dispatch => {
  return axios
    .post("/api/classifiers/eventTypes/delete", { _id })
    .then(res => {
      dispatch(removeEventType(_id));
      dispatch(closeModal(EVENT_TYPES_MODAL));
    })
    .catch(err => {
      // dispatch({
      //   type: GET_ERRORS,
      //   payload: err.response.data
      // });
    });
};
