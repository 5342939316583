import { t } from "../helpers/common";

export enum WidgetTypes {
    user_actions = "user_actions",
}

interface IWidget {
    value: WidgetTypes;
    translationString: string;
}

export const LIST: IWidget[] = [
    {
        value: WidgetTypes.user_actions,
        translationString: t("constants.widgets.users", "Users activity widget"),
    },
];