import React, { useCallback, useEffect, useState } from "react";

// constants
import * as PERMISSIONS from "../../constants/permissions";
import { WORKFLOWS } from "../../constants/modules";

// utils
import { useTranslation } from "react-i18next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { useAppDispatch } from "../../hooks/redux";
import { useHistory } from "react-router-dom";

//Redux
import { WORKFLOW_MODAL } from "../../modals/types";
import { openModal } from "../../actions/modals";

// components
import SimpleHeader from "../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../components/LoadingOverlay";
import {
  Button,
  Card,
  CardFooter,
  Container,
  Row,
  CardHeader,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import PermissionCheck from "../../components/PermissionCheck";

// Parts
import { IPaginationDocs } from "../../types/PaginationData";
import { Workflow } from "../../types/Workflow";
import BootstrapTable from "react-bootstrap-table-next";

const Workflows: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IPaginationDocs<Workflow>>({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
    sort: { field: "created", order: "desc" },
  });

  const onTableChange = (
    type: any,
    { page, sizePerPage, filters, sortField, sortOrder, cellEdit }: any
  ) => {
    switch (type) {
      case "sort":
        setData((d) => ({ ...d, page: 1, sort: { field: sortField, order: sortOrder } }));
        break;
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  const loadData = useCallback(() => {
    if (loading) {
      return;
    }

    setLoading(true);

    axios
      .get<IPaginationDocs<Workflow>>("/api/workflows", {
        params: {
          page: data.page,
          limit: data.limit,
          sort: qs.stringify(data.sort),
        },
      })
      .then((response) => {
        setData((d) => ({ ...d, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  }, [data.limit, data.page, data.sort]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-random mr-1" />
            {t("pages.workflows")}
            <i id="workflow-info" className="fas fa-info-circle text-info pl-2" />
            <UncontrolledTooltip placement="right" delay={0} target="workflow-info">
              {t("info.workflow")}
            </UncontrolledTooltip>
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={loading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}>
                      {/* @ts-ignore */}
                      <PermissionCheck module={WORKFLOWS} level={PERMISSIONS.CONTRIBUTE}>
                        <Button
                          className="btn-round btn-icon"
                          color="primary"
                          id="tooltip443412080"
                          onClick={() =>
                            dispatch(
                              openModal(WORKFLOW_MODAL, {
                                // siteId,
                              })
                            )
                          }
                          size="sm"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-plus" />
                          </span>
                          <span className="btn-inner--text">{t("buttons.create")}</span>
                        </Button>
                        <UncontrolledTooltip delay={0} target="tooltip443412080">
                          {t("tooltips.createNewWorkflow")}
                        </UncontrolledTooltip>
                      </PermissionCheck>
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  defaultSorted={[
                    {
                      dataField: "created",
                      order: "desc",
                    },
                  ]}
                  columns={[
                    {
                      dataField: "name",
                      text: t("fields.name"),
                    },
                    {
                      dataField: "created",
                      text: t("fields.created"),
                      sort: true,
                      formatter: (finishedAt) => {
                        return moment(finishedAt).format("L LT");
                      },
                      headerStyle: { width: 180 },
                    },
                  ]}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      history.push(`/workflows/${row._id}`);
                    },
                  }}
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                />
                <CardFooter className="py-4"></CardFooter>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Workflows;
