import * as yup from "yup";

import { fields } from "../../constants/formFields";

const inputItem = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  required: yup.bool(),
  minLength: yup
    .number()
    .required()
    .min(0)
    .max(yup.ref("maxLength"), "Min value can't be larger than max"),
  maxLength: yup
    .number()
    .required()
    .min(yup.ref("minLength"), "Max value can't be smaller than min")
    .max(1000),
});

const inputNumberItem = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  required: yup.bool(),
  min: yup
    .number()
    .required()
    .min(0)
    .max(yup.ref("max"), "Min value can't be larger than max"),
  max: yup
    .number()
    .required()
    .min(yup.ref("min"), "Max value can't be smaller than min"),
});

const textAreaItem = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  required: yup.bool(),
  minLength: yup
    .number()
    .required()
    .min(0)
    .max(yup.ref("maxLength"), "Min value can't be larger than max"),
  maxLength: yup
    .number()
    .required()
    .min(yup.ref("minLength"), "Max value can't be smaller than min")
    .max(10000),
});

const checkboxItem = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  required: yup.bool(),
});

const formFieldSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  description: yup.string().max(2500),
  enabled: yup.bool(),
  fields: yup
    .array()
    .test("has-any", "Form should have at least one field", (value) => {
      if (value) {
        return value.length > 0;
      }

      return false;
    })
    .of(
      yup.lazy((field: any) => {
        const { type } = field;

        switch (type) {
          case fields.Input:
            return inputItem;
          case fields.InputNumber:
            return inputNumberItem;
          case fields.Textarea:
            return textAreaItem;
          case fields.Checkbox:
            return checkboxItem;
        }

        return yup.object().test("failed", "Unrecognized field", () => false);
      })
    ),
});

export default formFieldSchema;
