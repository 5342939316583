import React from "react";

// constants
import * as FORM_FIELDS from "../constants/formFields";

// utils
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// Redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";

// components
import { Button, Container, Modal, ListGroup, ListGroupItem } from "reactstrap";

interface IWorkflowStepsModalProps {
  modalId: string;
  onSelect: IOnSelect;
}

interface IOnSelect {
  (field: FORM_FIELDS.FormField): void;
}

export default function WorkflowStepsModal({ modalId, onSelect }: IWorkflowStepsModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.workflowSteps")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Container fluid>
          <ListGroup>
            {FORM_FIELDS.LIST.map((field, index) => (
              <ListGroupItem
                key={index}
                action
                onClick={() => {
                  onSelect(Object.assign(field.getDefaults(), {orderId: uuidv4()}));
                  close();
                }}
                disabled={field.disabled}
              >
                {t(field.translationString)}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Container>
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
