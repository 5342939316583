import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "./reducers";

// reducers
import modalsReducer from "./features/modals";

const store = configureStore({
  reducer: combineReducers({
    // TODO: 15.11.2021. get rid of rootReducer and use features aproach from now on.
    ...rootReducer,

    modals: modalsReducer,
  }),
  middleware: [thunk, createLogger()],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
