import React from "react";
// reactstrap components
import {
  FormGroup,
  Input,
  Button,
  InputGroupAddon,
  InputGroup
} from "reactstrap";
import uniqid from "uniqid";

class LabelPasswordInputGroup extends React.Component {
  state = {
    showPassword: false
  };
  constructor() {
    super();
    this.labelId = uniqid();
  }

  render() {
    const {
      label,
      placeholder,
      touched,
      validationErrors,
      buttonText,
      onClick,
      required,
      ...props
    } = this.props;

    const invalid = touched && (validationErrors ? true : false);

    return (
      <FormGroup>
        <label className="form-control-label" htmlFor={this.labelId}>
          {label}
          {required ? <span className="text-danger">*</span> : null}
        </label>
        <InputGroup className="mb-3">
          <Input
            type={this.state.showPassword ? "text" : "password"}
            id={this.labelId}
            placeholder={placeholder ? placeholder : label}
            invalid={invalid}
            {...props}
          />
          <InputGroupAddon addonType="append">
            <Button
              type="button"
              color="primary"
              outline
              onClick={() => {
                this.setState({ showPassword: !this.state.showPassword });
              }}
            >
              <span className="btn-inner--icon">
                <i
                  className={`fas ${
                    this.state.showPassword ? "fa-eye" : "fa-eye-slash"
                  }`}
                />
              </span>
            </Button>
          </InputGroupAddon>
          <InputGroupAddon addonType="append">
            <Button type="button" color="primary" outline onClick={onClick}>
              <span className="btn-inner--icon">
                <i className="fas fa-dice" />
              </span>
            </Button>
          </InputGroupAddon>
        </InputGroup>
        {invalid ? (
          <div className="invalid-feedback">{validationErrors}</div>
        ) : null}
      </FormGroup>
    );
  }
}

export default LabelPasswordInputGroup;
