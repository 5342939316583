import React, { FunctionComponent, ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { TranslationProps } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { v4 as uuidv4 } from "uuid";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from "reactstrap";

export enum ActionItemTypes {
  Button = "button",
  Link = "link",
}

export enum Triggers {
  Hover = "hover",
  Click = "click",
}

interface IElementBase {
  icon: string;
  tooltip: TranslationProps;
}

interface IButton extends IElementBase {
  type: ActionItemTypes.Button;
  click(): void;
}

interface ILink extends IElementBase {
  type: ActionItemTypes.Link;
  link: string;
}

type Types = ILink | IButton;

interface IActionItems {
  elements: Types[];
  count?: number;
  trigger?: Triggers;
  icon?: ReactNode;
  link?: boolean;
  delay?: number;
}

const ActionItems: FunctionComponent<IActionItems> = (props) => {
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const debounced = useDebouncedCallback((open: boolean) => {
    setDropdownOpen(open);
  }, props.delay);

  return (
    <>
      {props.elements.length > (props.count ? props.count : 0) ? (
        <div>
          <Dropdown
            className="position-static"
            direction="right"
            isOpen={isDropdownOpen}
            onMouseEnter={() => {
              if (props.trigger === Triggers.Hover) {
                setDropdownOpen(true);
                debounced(true);
              }
            }}
            onMouseLeave={() => {
              if (props.trigger === Triggers.Hover) {
                debounced(false);
              }
            }}
            toggle={() => {
              if (props.trigger === Triggers.Click) {
                setDropdownOpen((e) => !e);
              }
            }}
          >
            <DropdownToggle className={`${props.link ? "btn-link" : null} btn-sm mr-0`}>
              {props.icon}
            </DropdownToggle>
            <DropdownMenu>
              {props.elements.map((element, index) => (
                <div key={`actionItem_${index}`}>
                  {element.type === ActionItemTypes.Button ? (
                    <DropdownItem
                      className="d-flex align-items-center font-weight-light"
                      onClick={() => element.click()}
                    >
                      <i className={`fas fa-${element.icon}`}></i>
                      {element.tooltip}
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      className="d-flex align-items-center font-weight-light"
                      tag={Link}
                      to={element.link}
                    >
                      <i className={`fas fa-${element.icon}`}></i>
                      {element.tooltip}
                    </DropdownItem>
                  )}
                </div>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : (
        props.elements.map((element, index) => {
          const uuid = "tooltip_" + uuidv4();
          return (
            <div className="d-inline-flex" key={`actionItem_${index}`}>
              {element.type === ActionItemTypes.Button ? (
                <button
                  id={uuid}
                  type="button"
                  className="btn btn-link table-action"
                  onClick={() => element.click()}
                >
                  <i className={`fas fa-${element.icon}`}></i>
                  <UncontrolledTooltip delay={0} target={uuid}>
                    {element.tooltip}
                  </UncontrolledTooltip>
                </button>
              ) : (
                <Link to={element.link} id={uuid} className="btn btn-link table-action">
                  <i className={`fas fa-${element.icon}`}></i>
                  <UncontrolledTooltip delay={0} target={uuid}>
                    {element.tooltip}
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          );
        })
      )}
    </>
  );
};

ActionItems.defaultProps = {
  count: 1,
  trigger: Triggers.Hover,
  icon: <i className="fas fa-ellipsis-v" />,
  link: true,
  delay: 200,
};

export default ActionItems;
