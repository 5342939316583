import React, { useEffect, useState } from "react";

import { closeModalById } from "../actions/modals";
import { useTranslation } from "react-i18next";

import { Modal, Button } from "reactstrap";
import { useDispatch } from "react-redux";

interface IProps {
    modalId: string;
    title: string;
    message: string;
    buttonText?: string;
    onSuccessCallback(): void;
  }

  export default function ApproveDialogModal( {modalId, title, message, buttonText, onSuccessCallback}: IProps) {
      
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const close = () => {
        dispatch(closeModalById(modalId));
    };

    return(
        <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
            <div className="modal-header justify-content-center">
                <h6 className="modal-title">{title}</h6>
            </div>
            <div className="modal-body pt-0 text-center">
                <p>
                  {message}  
                </p>
                <Button
                color="danger"
                type="button"
                onClick={() => {
                    onSuccessCallback();
                    close();
                }}
                >
                    {buttonText ? buttonText : t("buttons.delete")}
                </Button>
                <Button
                data-dismiss="modal"
                type="button"
                onClick={close}
                >
                   {t("buttons.cancel")}
                </Button>
            </div>
        </Modal>
    );
  }
