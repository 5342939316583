import React, { useState } from "react";

// constants & types
import { IWorkflowDuplicateModalProps } from "../types/Modal";
import { MODULES } from "../constants/modules";
import { ROLE_PERMISSIONS } from "../constants/rolePermissions";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useAppDispatch } from "../hooks/redux";
import { closeModalById } from "../features/modals";
import workflowDuplicateSchema, { WorkflowDuplicateBase } from "../schemas/workflowDuplicate";
import { toast } from "react-toastify";

// components
import { Button, Form, Modal } from "reactstrap";
import { Formik } from "formik";

import PermissionGuard from "../components/PermissionGuard";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

export default function WorkflowDuplicateModal(props: IWorkflowDuplicateModalProps) {
  const { id, callback, workflowId, workflowName } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [submitting, setSubmitting] = useState(false);

  const onSubmit = (values: WorkflowDuplicateBase) => {
    setSubmitting(true);
    axios
      .post<string>(`/api/workflows/${workflowId}/duplicate`, {
        ...values,
      })
      .then((response) => {
        toast(t("messages.workflowDuplicatedSuccessfully"));
        callback(response.data);
        close();
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(id));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          name: `${workflowName} - COPY`,
        }}
        validationSchema={workflowDuplicateSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">{t("modalTitles.duplicateWorkflow")}</h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
            </div>
            <div
              className="modal-footer"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
              <PermissionGuard module={MODULES.Workflows} permission={ROLE_PERMISSIONS.WRITE}>
                {({ hasAccess }) => (
                  <SpinnerButton
                    isLoading={submitting}
                    disabled={!hasAccess}
                    type="submit"
                    className="ml-auto"
                    color="primary"
                  >
                    {t("buttons.duplicate")}
                  </SpinnerButton>
                )}
              </PermissionGuard>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
