import React, { useEffect, useState } from "react";

// utils
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import moment from "moment";
import axios from "axios";
import prettyBytes from "pretty-bytes";
import eventSchema from "../schemas/event";
import _ from "lodash";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { closeModalById } from "../actions/modals";

// components
import Lightbox from "react-image-lightbox";
import { Button, Form, Modal, ListGroup, ListGroupItem, Row, Col } from "reactstrap";

import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";
import LabelDatePickerFormGroup from "../components/Inputs/LabelDatePickerFormGroup";
import LabelSelectFormGroup from "../components/Inputs/LabelSelectFormGroup";
import LabelMultipleFiles from "../components/Inputs/LabelMultipleFiles";
import Thumbnail from "../components/Thumbnail";

function EventModal(props) {
  const { modalId, siteId, data, onUpdate, onAdd, onDelete } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const eventTypes = useSelector((state) => state.eventTypes);

  const [currentData, setCurrentData] = useState({ images: [] });
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleteing] = useState(false);
  const [newImages, setNewImages] = useState([]);

  const [lightboxImages, setLightboxImages] = useState();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    if (data) {
      const { eventType, ...rest } = data;
      if (eventType) {
        setCurrentData({ ...rest, eventType: eventType._id });
      } else {
        setCurrentData({ ...rest });
      }
    }
  }, [data]);

  useEffect(() => {
    prepareLightboxImages();
  }, [newImages, currentData.images]);

  const prepareLightboxImages = async () => {
    let tmp = [];

    for (const file of newImages) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = (e) => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });

      tmp.push(result_base64);
    }

    setLightboxImages([
      ...tmp,
      ...currentData.images.map((image) => {
        return `${process.env.REACT_APP_API_URL}uploads/${image.original.key}`;
      }),
    ]);
  };

  const handleDelete = (event) => {
    setDeleteing(true);
    axios
      .delete(`/api/events/site/${event.site}/${event._id}`)
      .then(() => {
        onDelete();
        close();
      })
      .catch((err) => {
        setDeleteing(false);
      });
  };

  const onSubmit = (values) => {
    setSubmitting(true);

    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    newImages.forEach((image) => {
      formData.append("files", image);
    });

    if (values._id) {
      axios
        .put("/api/events", formData)
        .then((res) => {
          setNewImages([]);

          onUpdate(res.data);

          const { eventType, ...rest } = res.data;
          setCurrentData({ ...rest, eventType: eventType._id });

          setSubmitting(false);
        })
        .catch((err) => {
          console.log("putErr:", err);
        });
    } else {
      axios
        .post("/api/events", formData)
        .then((res) => {
          onAdd();
          close();
        })
        .catch((err) => {
          console.log("postErr:", err);
        });
    }
  };

  const onImageDelete = (eventId, imageId) => {
    setSubmitting(true);
    axios
      .post("/api/events/image/delete", {
        siteId,
        eventId,
        imageId,
      })
      .then(() => {
        // TODO: set response data as current data
        setCurrentData((e) => {
          const tmp = {
            ...e,
            images: e.images.filter((image) => image._id !== imageId),
          };
          return tmp;
        });

        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };
  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          siteId,
          eventType: "",
          occured: moment(),
          description: "",
          ...currentData,
        }}
        validationSchema={eventSchema()}
        onSubmit={onSubmit}
        enableReinitialize={currentData._id ? true : false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          dirty,
        }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id ? t("modalTitles.editEvent") : t("modalTitles.newEvent")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelSelectFormGroup
                label={t("fields.type")}
                name="eventType"
                value={values.eventType}
                onChange={handleChange}
                options={eventTypes.map((eventType) => {
                  return { value: eventType._id, name: eventType.name };
                })}
                touched={touched.eventType}
                validationErrors={errors.eventType}
              />
              <LabelDatePickerFormGroup
                label={t("fields.occured")}
                value={moment(values.occured)}
                onChange={(e) => setFieldValue("occured", e)}
                touched={touched.occured}
                validationErrors={errors.occured}
              />
              <LabelFormGroup
                label={t("fields.description")}
                type="textarea"
                name="description"
                value={values.description}
                onChange={handleChange}
                touched={touched.description}
                validationErrors={errors.description}
              />
              <LabelMultipleFiles
                label={t("fields.images")}
                callback={(files) => {
                  setNewImages((e) => [...e, ...files]);
                }}
              />
              <ListGroup className="list-group-lg" flush>
                {[...newImages, ...currentData.images].map((image, index) => (
                  <ListGroupItem key={image._id ? image._id : image.lastModified} className="px-0">
                    <Row className="align-items-center">
                      <Col className=" col-auto">
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setImageIndex(index);
                            setLightboxOpen(true);
                          }}
                        >
                          <div className=" avatar">
                            <Thumbnail
                              hoverable
                              value={
                                image._id
                                  ? `${process.env.REACT_APP_API_URL}uploads/${image.thumbnail.key}`
                                  : image
                              }
                            />
                          </div>
                        </a>
                      </Col>
                      <div className=" col ml--3">
                        <h4 className=" mb-1">{image._id ? image.originalName : image.name}</h4>
                        <p className=" small text-muted mb-0">
                          {prettyBytes(image._id ? image.original.size : image.size)}
                        </p>
                      </div>
                      <Col className=" col-auto">
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => {
                            if (image._id) {
                              onImageDelete(values._id, image._id);
                            } else {
                              setNewImages((e) =>
                                _.filter(e, (n) => {
                                  return n.lastModified !== image.lastModified;
                                })
                              );
                            }
                          }}
                        >
                          <i className="fas fa-trash" />
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
            <div className="modal-footer">
              {values._id ? (
                <SpinnerButton
                  isLoading={deleting}
                  color="danger"
                  onClick={() => handleDelete(values)}
                  disabled={deleting || submitting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null}

              <SpinnerButton
                disabled={!dirty && newImages.length === 0}
                isLoading={submitting}
                type="submit"
                className="ml-auto"
                color="primary"
              >
                {values._id ? t("buttons.save") : t("buttons.create")}
              </SpinnerButton>
              <Button color="link" data-dismiss="modal" type="button" onClick={close}>
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>

      {lightboxOpen ? (
        <Lightbox
          mainSrc={lightboxImages[imageIndex]}
          nextSrc={lightboxImages[(imageIndex + 1) % lightboxImages.length]}
          prevSrc={lightboxImages[(imageIndex + lightboxImages.length - 1) % lightboxImages.length]}
          onCloseRequest={() => {
            setLightboxOpen(false);
          }}
          onMovePrevRequest={() => {
            setImageIndex((imageIndex + lightboxImages.length - 1) % lightboxImages.length);
          }}
          onMoveNextRequest={() => {
            setImageIndex((imageIndex + 1) % lightboxImages.length);
          }}
        />
      ) : null}
    </Modal>
  );
}

export default EventModal;
