import * as yup from "yup";

const qrSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  qrCollection: yup.string().required(),
  description: yup.string().max(2500),
  scan: yup.string().required(),
  enableLocation: yup.bool(),
  enabled: yup.bool(),
});

export default qrSchema;
