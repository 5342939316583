import { t } from "../helpers/common";

export const SCHEDULED = "scheduled";
export const ARRIVED = "arrived";
export const LEFT = "left";

export const LIST = [
  {
    value: SCHEDULED,
    translationString: t("constants.visitorStatuses.scheduled"),
  },
  {
    value: ARRIVED,
    translationString: t("constants.visitorStatuses.arrived"),
  },
  {
    value: LEFT,
    translationString: t("constants.visitorStatuses.left"),
  },
];
