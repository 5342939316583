import moment from "moment";

export const objectIdToTimestamp = (objectId) => {
  return parseInt(objectId.toString().substr(0, 8), 16) * 1000;
};

export const formatDate = (date) => {
  return moment(date).format("DD.MM.YYYY.");
};

export const formatDatetime = (date) => {
  return moment(date).format("DD.MM.YYYY. HH:mm");
};

export const formatTime = (date) => {
  return moment(date).format("HH:mm");
};
