import { SET_AUTHENTICATED, SET_USER_DATA, SET_LOGOUT } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  user: {
    workspace: {
      modules: [],
    },
    roles: [],
  },
  logout: () => {},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case SET_LOGOUT:
      if (typeof action.payload === "function") {
        return {
          ...state,
          logout: action.payload,
        };
      }
      return state;
    default:
      return state;
  }
}
