/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";

// Loader
import ReactLoadingOverlay from "react-loading-overlay";
import BeatLoader from "react-spinners/BeatLoader";

function LoadingOverlay(props) {
  return (
    <ReactLoadingOverlay
      active={props.isLoading}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(255, 255, 255, 0.7)",
        }),
      }}
      spinner={<BeatLoader size={15} color={"#11cdef"} loading={true} />}
    >
      {props.children}
    </ReactLoadingOverlay>
  );
}

LoadingOverlay.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

export default LoadingOverlay;
