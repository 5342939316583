/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import Copyright from "../Copyright";

export default function Footer(props) {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col className="text-left">
              <Copyright/>
            </Col>
            <Col className="copyright text-right text-muted">
              v {process.env.REACT_APP_VERSION}
            </Col>
          </Row>
        </footer>
      </Container>
    </>
  );
}
