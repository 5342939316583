import React from "react";
import { FormField } from "../../../../../constants/formFields";

// utils
import { useTranslation } from "react-i18next";
import _ from "lodash";

// components
import BootstrapTable from "react-bootstrap-table-next";

interface Props {
  value?: string;
}

export default function LicensePlateStepData({ value }: Props) {
  const { t } = useTranslation();

  return (
    <BootstrapTable
      keyField="_id"
      data={[{ name: t("fields.value"), value: value }]}
      columns={[
        {
          dataField: "name",
          text: t("fields.field"),
        },
        {
          dataField: "value",
          text: t("fields.value"),
        },
      ]}
    />
  );
}
