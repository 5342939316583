import * as types from "./types";

export const setPaginationData = (scope, payload) => {
  return {
    type: scope + types.SET_PAGINATION,
    payload,
  };
};

export const setLoading = (scope, payload) => {
  return {
    type: scope + types.SET_LOADING,
    payload,
  };
};

export const setSidebarOpen = (payload) => {
  return {
    type: types.SET_SIDEBAR_OPEN,
    payload,
  };
};
