import React, { useEffect, useState } from "react";

// react library for routing
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";

// utils
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import _ from "lodash";

// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from "react-perfect-scrollbar";

// components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from "reactstrap";
import Item from "./Item";
import Collapsible from "./Collapsible";

export default function Sidebar(props) {
  const { routes, open, logo, onToggle, match } = props;

  const location = useLocation();

  const [primaryRoutes, setPrimaryRoutes] = useState([]);
  const [subRoutes, setSubRoutes] = useState([]);

  const { t } = useTranslation();

  // makes the sidenav normal on hover (actually when mouse enters on it)
  const onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  };

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  const onMouseLeaveSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  };

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  const closeSidenav = () => {
    if (window.innerWidth < 1200) {
      onToggle();
    }
  };

  useEffect(() => {
    setPrimaryRoutes(routes.filter((route) => !route.subRoute && !route.exclude));
  }, [routes]);

  useEffect(() => {
    setSubRoutes(
      routes.filter((route) => {
        if (route.subRoute) {
          const regex = RegExp(route.match);
          return regex.test(location.pathname);
        } else {
          return false;
        }
      })
    );
  }, [routes, location.pathname]);

  return (
    <Navbar
      className="sidenav navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
      onMouseEnter={onMouseEnterSidenav}
      onMouseLeave={onMouseLeaveSidenav}
    >
      <PerfectScrollbar>
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center">
            {logo ? (
              <NavbarBrand tag={Link} to="/">
                <img className="navbar-brand-img" src={logo.src} alt={logo.alt} />
              </NavbarBrand>
            ) : null}
            <div className="ml-auto">
              <div
                className={classnames("sidenav-toggler d-none d-xl-block", {
                  active: open,
                })}
                onClick={onToggle}
              >
                <div className="sidenav-toggler-inner">
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                  <i className="sidenav-toggler-line" />
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-inner">
            <Collapse navbar isOpen={true}>
              <Nav navbar>
                {primaryRoutes.map((route, key) => {
                  if (route.routes) {
                    return <Collapsible key={key} route={route} onClick={closeSidenav} />;
                  } else {
                    return <Item key={key} route={route} onClick={closeSidenav} />;
                  }
                })}
              </Nav>
              {subRoutes.length > 0 ? (
                <>
                  <hr className="my-3" />
                  <h6 className="navbar-heading p-0 text-muted">
                    <span className="docs-normal">{t("fields.site")}</span>
                  </h6>
                  <Nav navbar>
                    {subRoutes.map((route, key) => {
                      let path = route.path;

                      _.forEach(match.match.params, (value, key) => {
                        const regex = RegExp(`:${key}`);
                        path = path.replace(regex, value);
                      });

                      if (route.routes) {
                        return (
                          <Collapsible
                            key={key}
                            route={{
                              ...route,
                              routes: route.routes.map((route) => {
                                let subPath = route.path;

                                _.forEach(match.match.params, (value, key) => {
                                  const regex = RegExp(`:${key}`);
                                  subPath = subPath.replace(regex, value);
                                });

                                return { ...route, path: subPath };
                              }),
                              path,
                            }}
                            onClick={closeSidenav}
                          />
                        );
                      } else {
                        return (
                          <NavItem key={key}>
                            <NavLink to={path} exact onClick={closeSidenav} tag={NavLinkRRD}>
                              {route.icon}
                              <span
                                className="nav-link-text"
                                style={{
                                  textDecoration: route.depricated ? "line-through" : "none",
                                }}
                              >
                                {t(route.translationString)}
                              </span>
                            </NavLink>
                          </NavItem>
                        );
                      }
                    })}
                  </Nav>
                </>
              ) : null}
            </Collapse>
          </div>
        </div>
      </PerfectScrollbar>
    </Navbar>
  );
}
