import React from "react";
import TimeAgo from "../../../components/TimeAgo";

export default function AccessLogRow(props) {
  const { log } = props;

  return (
    <>
      <td>
        {log.user.name
          ? log.user.name
          : `${log.user.firstName} ${log.user.lastName}`}
      </td>
      <td>{log.ip}</td>
      <td>
        <TimeAgo time={log.created} />
      </td>
    </>
  );
}
