import {
  SET_LOCKERS,
  REPLACE_LOCKER,
  PREPEND_LOCKER,
  REMOVE_LOCKER
} from "../../actions/types";

const initialState = {
  list: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LOCKERS:
      return {
        ...state,
        list: action.lockers
      };
    case REPLACE_LOCKER:
      return {
        ...state,
        list: state.list.map((locker, index) => {
          return action.locker._id === locker._id ? action.locker : locker;
        })
      };
    case PREPEND_LOCKER:
      return {
        ...state,
        list: [action.locker, ...state.list]
      };
    case REMOVE_LOCKER:
      return {
        ...state,
        list: state.list.filter(item => item._id !== action.id)
      };
    default:
      return state;
  }
}
