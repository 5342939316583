import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { setSidebarOpen } from "../actions/common";

import NavigationBar from "../components/Navbars/NavigationBar";
import Footer from "../components/Footers/Footer.jsx";

import ModalManager from "../components/ModalManager.jsx";
import ModalManagerV2 from "../managers/Modal";

export default function Layout(props) {
  const { routes, component, ...rest } = props;
  const Component = component;

  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-pinned");
    }
    dispatch(setSidebarOpen(isOpen));
  }, [isOpen]);

  return (
    <>
      <div
        className="main-content"
        // ref="mainContent"
        onClick={() => {
          if (isOpen && window.innerWidth < 1200) {
            setOpen(false);
          }
        }}
      >
        <NavigationBar
          {...rest}
          open={isOpen}
          onToggle={() => {
            setOpen((e) => !e);
          }}
        />
        <div style={{ minHeight: "calc(100vh - 128px)" }}>
          <Component {...rest} />
        </div>
        <Footer />
        <ModalManager />
        <ModalManagerV2 />
      </div>
    </>
  );
}

//   componentDidUpdate(e) {
//     if (e.history.pathname !== e.location.pathname) {
//       document.documentElement.scrollTop = 0;
//       document.scrollingElement.scrollTop = 0;
//       this.refs.mainContent.scrollTop = 0;
//     }
//   }
