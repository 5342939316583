import React, { useState } from "react";

// constants
import * as scopes from "../actions/scopes";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";

// redux
import { useDispatch } from "react-redux";
import { closeModalById } from "../actions/modals";
import { setPaginationData } from "../actions/common";

import { Button, Modal, UncontrolledTooltip, Table } from "reactstrap";

// components
import LabelSelectFormGroup from "../components/Inputs/LabelSelectFormGroup";
import { useEffect } from "react";

export default function UserSitesModal(props) {
  const { modalId, data } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const [submitting, setSubmitting] = useState(false);
  const [site, setSite] = useState("");
  const [user, setUser] = useState(data);
  const [sites, setSites] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  // const [sitesLoading, setSitesLoading] = useState(true);

  useEffect(() => {
    axios
      .post("/api/sites/getAll")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // setSitesLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredSites(
      sites.filter((value) => {
        var tmp = true;

        if (user.sites) {
          user.sites.forEach((userSite) => {
            if (userSite._id === value._id) {
              tmp = false;
              return;
            }
          });
        }

        return tmp;
      })
    );
  }, [sites, user.sites]);

  const handleRemove = (siteId) => {
    axios
      .post("/api/users/removeSite", { _id: user._id, siteId })
      .then((res) => {
        setUser(res.data);
        dispatch(
          setPaginationData(scopes.USERS, {
            timestamp: moment().unix(),
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setSite("");
      });
  };

  const handleChange = (e) => {
    setSite(e.target.value);
    axios
      .post("/api/users/addSite", { _id: user._id, siteId: e.target.value })
      .then((res) => {
        setUser(res.data);
        dispatch(
          setPaginationData(scopes.USERS, {
            timestamp: moment().unix(),
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setSite("");
      });
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <div className="modal-header">
        <h6 className="modal-title">{t("modalTitles.userSites")}</h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={close}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Table
          className="align-items-center table-flush table-hover"
          responsive
          striped
        >
          <thead className="thead-light">
            <tr>
              <th>{t("fields.sites")}</th>
              <th style={{ width: 1 }} />
            </tr>
          </thead>
          <tbody>
            {user.sites &&
              user.sites.map((site, index) => (
                <tr key={index}>
                  <td>
                    <b>{site.name}</b>
                  </td>
                  <td className="table-action">
                    <button
                      id={`userTooltip${index}`}
                      type="button"
                      className="btn btn-link table-action"
                      onClick={() => handleRemove(site._id)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                    <UncontrolledTooltip
                      delay={0}
                      target={`userTooltip${index}`}
                    >
                      {t("tooltips.editUser")}
                    </UncontrolledTooltip>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <LabelSelectFormGroup
          label={t("fields.site")}
          name="site"
          value={site}
          onChange={handleChange}
          options={filteredSites}
          getOptionValue={(option) => option._id}
        />
      </div>
      <div className="modal-footer">
        <Button color="link" data-dismiss="modal" type="button" onClick={close}>
          {t("buttons.close")}
        </Button>
      </div>
    </Modal>
  );
}
