import React, { useState, useEffect } from "react";

// utils
import uniqid from "uniqid";

// components
import { FormGroup } from "reactstrap";

export default function LabelToggle(props) {
  const { label, placeholder, touched, validationErrors, required, ...rest } = props;

  const labelId = uniqid();

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(touched && (validationErrors ? true : false));
  }, [touched, validationErrors]);

  return (
    <FormGroup>
      <label className="form-control-label" htmlFor={labelId}>
        {label}
        {required ? <span className="text-danger">*</span> : null}
      </label>
      <div>
        <label className="custom-toggle">
          <input type="checkbox" {...rest} />
          <span className="custom-toggle-slider rounded-circle" />
        </label>
      </div>
      {invalid ? <div className="invalid-feedback">{validationErrors}</div> : null}
    </FormGroup>
  );
}
