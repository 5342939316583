import React, { useEffect, useState } from "react";

// interfaces
import { IChecklistStep, IFormStep, IStepParams } from "../../../../types/WorkflowSteps";
import { workflowStepTypes } from "../../../../constants/workflowSteps";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import formSchema from "../../../../schemas/workflowStep/form";

// components
import { Button, Form, ListGroup } from "reactstrap";
import SpinnerButton from "../../../../components/Buttons/SpinnerButton";

// components
import LabelFormGroup from "../../../../components/Inputs/LabelFormGroup";
import { FieldArray, Formik } from "formik";
import LabelToggle from "../../../../components/Inputs/LabelToggle";

import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import { useHistory, useParams } from "react-router-dom";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import SimpleHeader from "../../../../components/Headers/SimpleHeader";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import { CardFooter, FormGroup, ListGroupItem } from "reactstrap/lib";

import { useDispatch } from "react-redux";
import { IChecklistItem } from "../../../../types/ChecklistItem";
import {
  CHECKLIST_TYPES,
  workflowChecklistTypes,
} from "../../../../constants/workflowChecklistTypes";
import LabelSelectFormGroup from "../../../../components/Inputs/LabelSelectFormGroup";
import _ from "lodash";
import checklistSchema from "../../../../schemas/workflowStep/checklist";

export default function CheckList() {
  const { workflowId, stepId, siteId } = useParams<IStepParams>();

  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDeleting, setDeleteing] = useState(false);

  const [initialData, setInitialData] = useState<IChecklistStep>({
    type: workflowStepTypes.Checklist,
    name: "",
    description: "",
    enabled: true,
    items: [],
    enableLocation: false,
    check: workflowChecklistTypes.Single,
  });

  const onDelete = (_id: any) => {
    setDeleteing(true);
    axios
      .delete(`/api/workflows/${workflowId}/step/${_id}`)
      .then(() => {
        goBack();
      })
      .catch((err) => {
        console.log("err:", err);
        setDeleteing(false);
      });
  };

  const onSubmit = (values: IChecklistStep) => {
    const ordered: IChecklistStep = {
      ...values,
      items: values.items.map((item: IChecklistItem, index: number) => {
        delete item.orderId;
        return { ...item, order: index };
      }),
    };
    setSubmitting(true);
    axios
      .put<IChecklistStep>(`/api/workflows/${workflowId}/checklist`, ordered)
      .then((response) => {
        setInitialData(response.data);
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
      });
  };

  const goBack = () => {
    if (siteId) {
      history.push(`/site/${siteId}/tasks/${workflowId}`);
    } else {
      history.push(`/workflows/${workflowId}`);
    }
  };

  useEffect(() => {
    if (stepId) {
      setLoading(true);
      axios
        .get<IChecklistStep>(`/api/workflows/${workflowId}/step/${stepId}`)
        .then((response) => {
          setInitialData(response.data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("err:", err);
        });
    }
  }, []);

  useEffect(() => {
    if (!stepId && initialData._id) {
      if (siteId) {
        history.replace(`/site/${siteId}/tasks/${workflowId}/checklist/${initialData._id}`);
      } else {
        history.replace(`/workflows/${workflowId}/checklist/${initialData._id}`);
      }
    }
  }, [initialData]);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-users mr-1" />
            {t("pages.workflows")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs={12}></Col>
                  </Row>
                </CardHeader>
                <Formik
                  initialValues={initialData}
                  validationSchema={checklistSchema}
                  onSubmit={onSubmit}
                  enableReinitialize
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    dirty,
                  }) => (
                    <Form role="form" onSubmit={handleSubmit}>
                      <Container>
                        <LabelFormGroup
                          label={t("fields.name")}
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          touched={touched.name}
                          validationErrors={errors.name}
                        />
                        <FieldArray
                          name="items"
                          render={(arrayHelpers) => (
                            <FormGroup>
                              <label className="form-control-label">{t("fields.items")}</label>
                              <ListGroup>
                                <DragDropContext
                                  onDragEnd={(result: DropResult) => {
                                    if (result.destination) {
                                      arrayHelpers.move(
                                        result.source.index,
                                        result.destination.index
                                      );
                                    }
                                  }}
                                >
                                  <Droppable droppableId="workflow">
                                    {(provided, droppableSnapshot) => (
                                      <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {values.items &&
                                          values.items.map((item, index) => {
                                            return (
                                              <Draggable
                                                key={item.orderId ? item.orderId : item.order}
                                                draggableId={
                                                  item.orderId
                                                    ? `item${item.orderId}`
                                                    : `item${item.order}`
                                                }
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    className="pb-1"
                                                  >
                                                    <ListGroupItem>
                                                      <Container fluid>
                                                        {typeof errors === "string" && touched ? (
                                                          <Row className="justify-content-center">
                                                            <Col xs="auto">
                                                              <div
                                                                className="invalid-feedback"
                                                                style={{ display: "block" }}
                                                              >
                                                                {errors}
                                                              </div>
                                                            </Col>
                                                          </Row>
                                                        ) : null}
                                                        <Row className="align-items-center">
                                                          <Col
                                                            xs="auto"
                                                            {...provided.dragHandleProps}
                                                          >
                                                            <i
                                                              className="fas fa-grip-vertical"
                                                              style={{
                                                                paddingTop: 15,
                                                                paddingBottom: 15,
                                                              }}
                                                            />
                                                          </Col>
                                                          <Col>
                                                            <LabelFormGroup
                                                              label={t("fields.name")}
                                                              value={item.name}
                                                              onChange={(
                                                                e: React.ChangeEvent<
                                                                  HTMLInputElement
                                                                >
                                                              ) => {
                                                                setFieldValue(
                                                                  `items.${index}.name`,
                                                                  e.target.value
                                                                );
                                                              }}
                                                              touched={
                                                                touched && touched.items
                                                                  ? touched.items[index].name
                                                                  : false
                                                              }
                                                              validationErrors={
                                                                errors &&
                                                                errors.items &&
                                                                errors.items[index] &&
                                                                typeof errors.items[index] !==
                                                                  "string"
                                                                  ? // @ts-ignore
                                                                    errors.items[index].name
                                                                  : null
                                                              }
                                                            />
                                                          </Col>
                                                          <Col xs="auto">
                                                            <SpinnerButton
                                                              className="btn btn-link table-action"
                                                              type="button"
                                                              isLoading={false}
                                                              onClick={() =>
                                                                arrayHelpers.remove(index)
                                                              }
                                                            >
                                                              <span className="btn-inner--icon">
                                                                <i className="fas fa-trash" />
                                                              </span>
                                                            </SpinnerButton>
                                                          </Col>
                                                        </Row>
                                                      </Container>
                                                    </ListGroupItem>
                                                  </div>
                                                )}
                                              </Draggable>
                                            );
                                          })}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                {typeof errors.items === "string" && touched.items ? (
                                  <div className="invalid-feedback" style={{ display: "block" }}>
                                    {errors.items}
                                  </div>
                                ) : null}

                                <ListGroupItem
                                  style={{ border: 0, marginTop: 16 }}
                                  action
                                  onClick={(e) => {
                                    e.preventDefault();
                                    arrayHelpers.push({
                                      name: "",
                                      orderId: uuidv4(),
                                    });
                                  }}
                                >
                                  <Container fluid>
                                    <Row className="align-items-center justify-content-center">
                                      <Col xs="auto">
                                        <i className="fas fa-plus-circle" />
                                      </Col>
                                      <Col xs="auto">{t("buttons.addItem")}</Col>
                                    </Row>
                                  </Container>
                                </ListGroupItem>
                              </ListGroup>
                            </FormGroup>
                          )}
                        />

                        <LabelFormGroup
                          label={t("fields.description")}
                          name="description"
                          type="textarea"
                          optional
                          value={values.description}
                          onChange={handleChange}
                          touched={touched.description}
                          validationErrors={errors.description}
                        />
                        <LabelSelectFormGroup
                          label={t("fields.checklistType")}
                          name="check"
                          value={values.check}
                          onChange={handleChange}
                          options={_.map(CHECKLIST_TYPES, (check, index) => {
                            return {
                              value: index,
                              name: check.label,
                            };
                          })}
                          touched={touched.check}
                          validationErrors={errors.check}
                        />
                        <LabelToggle
                          label={t("fields.location")}
                          name="enableLocation"
                          checked={values.enableLocation}
                          onChange={handleChange}
                          touched={touched.enableLocation}
                          validationErrors={errors.enableLocation}
                        />
                        <LabelToggle
                          label={t("fields.enabled")}
                          name="enabled"
                          checked={values.enabled}
                          onChange={handleChange}
                          touched={touched.enabled}
                          validationErrors={errors.enabled}
                        />
                      </Container>
                      <CardFooter>
                        <Container>
                          <Row>
                            {values._id ? (
                              <Col>
                                <SpinnerButton
                                  isLoading={isDeleting}
                                  color="danger"
                                  onClick={() => onDelete(values._id)}
                                >
                                  {t("buttons.delete")}
                                </SpinnerButton>
                              </Col>
                            ) : null}
                            <Col>
                              <Row className="justify-content-end">
                                <Col xs="auto">
                                  <SpinnerButton
                                    isLoading={isSubmitting}
                                    type="submit"
                                    className="ml-auto"
                                    color="primary"
                                    disabled={!dirty}
                                  >
                                    {values._id ? t("buttons.save") : t("buttons.create")}
                                  </SpinnerButton>
                                </Col>
                                <Col xs="auto">
                                  <Button
                                    color="link"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={goBack}
                                  >
                                    {t("buttons.back")}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Container>
                      </CardFooter>
                    </Form>
                  )}
                </Formik>
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
