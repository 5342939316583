import React, { useState } from "react";

// utils
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Formik } from "formik";
import manageKeySchema from "../schemas/manageKey";

// redux
import { useDispatch } from "react-redux";

// actions
import { closeModalById } from "../actions/modals";

// components
import { Button, Form, Modal } from "reactstrap";
import SpinnerButton from "../components/Buttons/SpinnerButton";
import LabelFormGroup from "../components/Inputs/LabelFormGroup";

export default function ManageKeyModal(props) {
  const { modalId, siteId, lockerId, data, callback } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [isDeleting, setIsDeleting] = useState(false);

  const onSubmit = (values) => {
    setIsSubmitting(true);
    if (values._id) {
      axios
        .post("/api/keys/update", { siteId, lockerId, ...values })
        .then((res) => {
          callback(res.data);
          close();
        })
        .catch((err) => {
          console.log("error:", err);
        });
    } else {
      axios
        .post("/api/keys/create", {
          siteId,
          lockerId,
          ...values,
        })
        .then((res) => {
          callback(res.data);
          close();
        })
        .catch((err) => {
          console.log("error:", err);
        });
    }
  };

  const close = () => {
    dispatch(closeModalById(modalId));
  };

  return (
    <Modal className="modal-dialog-centered" isOpen={true} toggle={close}>
      <Formik
        initialValues={{
          _id: null,
          name: "",
          ...data,
        }}
        validationSchema={manageKeySchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => (
          <Form role="form" onSubmit={handleSubmit}>
            <div className="modal-header">
              <h6 className="modal-title">
                {values._id
                  ? t("modalTitles.editKey")
                  : t("modalTitles.createKey")}
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <LabelFormGroup
                label={t("fields.name")}
                name="name"
                value={values.name}
                onChange={handleChange}
                touched={touched.name}
                validationErrors={errors.name}
              />
            </div>
            <div className="modal-footer">
              {/* {values._id ? (
                <SpinnerButton
                  color="danger"
                  onClick={() => {
                    setIsDeleteing(true);
                    dispatch(deleteEventType(values._id));
                  }}
                  isLoading={isDeleting}
                  disabled={isDeleting || isSubmitting}
                >
                  {t("buttons.delete")}
                </SpinnerButton>
              ) : null} */}
              <SpinnerButton
                className="ml-auto"
                color="primary"
                type="submit"
                isLoading={isSubmitting}
                // disabled={isDeleting}
              >
                {t("buttons.save")}
              </SpinnerButton>
              <Button
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={close}
              >
                {t("buttons.close")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
