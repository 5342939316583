import * as yup from "yup";
import moment from "moment";

const keySchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  note: yup.string(),
  issued: yup
    .date("validation.invalidDate")
    .nullable()
    .max(moment().add(1, "minute")),
  returned: yup
    .date("validation.invalidDate")
    .nullable()
    .max(moment().add(1, "minute")),
});

export default keySchema;
