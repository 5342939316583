// common
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_LOADING = "SET_LOADING";
export const SET_SIDEBAR_OPEN = "SET_SIDEBAR_OPEN";

export const GET_ERRORS = "GET_ERRORS";

// Auth
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_USER_DATA = "SET_USER_DATA";

// Modal
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const CLOSE_MODAL_BY_ID = "CLOSE_MODAL_BY_ID";

// Site
export const SET_CURRENT_SITE = "SET_CURRENT_SITE";

// Admin Manage users
export const SET_USERS = "SET_USERS";
export const SET_ACCESS_LOG = "SET_ACCESS_LOG";

export const SET_WORKSPACES = "SET_WORKSPACES";

// Roles
export const SET_ROLES = "SET_ROLES";
export const APPEND_ROLE = "APPEND_ROLE";
export const REPLACE_ROLE = "REPLACE_ROLE";
export const REMOVE_ROLE = "REMOVE_ROLE";

// Admin Manage sites
export const SET_SITES = "SET_SITES";
export const SET_ALL_SITES = "SET_ALL_SITES";
export const PREPEND_SITE = "PREPEND_SITE";
export const REPLACE_SITE = "REPLACE_SITE";
export const REMOVE_SITE = "REMOVE_SITE";

// Admin Event types
export const SET_EVENT_TYPES = "SET_EVENT_TYPES";
export const PREPEND_EVENT_TYPE = "PREPEND_EVENT_TYPE";
export const REPLACE_EVENT_TYPE = "REPLACE_EVENT_TYPE";
export const REMOVE_EVENT_TYPE = "REMOVE_EVENT_TYPE";

// Admin Transport types
export const SET_TRANSPORT_TYPES = "SET_TRANSPORT_TYPES";
export const PREPEND_TRANSPORT_TYPE = "PREPEND_TRANSPORT_TYPE";
export const REPLACE_TRANSPORT_TYPE = "REPLACE_TRANSPORT_TYPE";
export const REMOVE_TRANSPORT_TYPE = "REMOVE_TRANSPORT_TYPE";

// Admin Positions
export const SET_POSITIONS = "SET_POSITIONS";
export const PREPEND_POSITION = "PREPEND_POSITION";
export const REPLACE_POSITION = "REPLACE_POSITION";
export const REMOVE_POSITION = "REMOVE_POSITION";

// Admin Employees
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const PREPEND_EMPLOYEE = "PREPEND_EMPLOYEE";
export const REPLACE_EMPLOYEE = "REPLACE_EMPLOYEE";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

// Admin Lockers
export const SET_LOCKERS = "SET_LOCKERS";
export const PREPEND_LOCKER = "PREPEND_LOCKER";
export const REPLACE_LOCKER = "REPLACE_LOCKER";
export const REMOVE_LOCKER = "REMOVE_LOCKER";

// User sites
export const SET_USER_SITES = "SET_USER_SITES";

// events
export const SET_EVENTS = "SET_EVENTS";

// transports
export const SET_TRANSPORTS = "SET_TRANSPORTS";
export const PREPEND_TRANSPORT = "PREPEND_TRANSPORT";
export const REPLACE_TRANSPORT = "REPLACE_TRANSPORT";
export const REMOVE_TRANSPORT = "REMOVE_TRANSPORT";

// visitors
export const SET_VISITORS = "SET_VISITORS";
export const PREPEND_VISITOR = "PREPEND_VISITOR";
export const REPLACE_VISITOR = "REPLACE_VISITOR";
export const REMOVE_VISITOR = "REMOVE_VISITOR";

// keys
export const SET_KEYS = "SET_KEYS";
export const PREPEND_KEY = "PREPEND_KEY";
export const REPLACE_KEY = "REPLACE_KEY";
export const REMOVE_KEY = "REMOVE_KEY";

// workflows
export const SET_WORKFLOWS = "SET_WORKFLOWS";
