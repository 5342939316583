import * as yup from "yup";

const workspaceSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(30),
});

export default workspaceSchema;
