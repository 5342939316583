import {
  SET_EVENT_TYPES,
  REPLACE_EVENT_TYPE,
  PREPEND_EVENT_TYPE,
  REMOVE_EVENT_TYPE,
} from "../../actions/types";

const initialState = [];

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_EVENT_TYPES:
      return action.eventTypes;
    case PREPEND_EVENT_TYPE:
      return [action.payload, ...state];
    case REPLACE_EVENT_TYPE:
      return state.map((eventType) => {
        return action.payload._id === eventType._id
          ? action.payload
          : eventType;
      });
    case REMOVE_EVENT_TYPE:
      return state.filter((item) => item._id !== action.payload);
    default:
      return state;
  }
}
