import React from "react";

import { Document, Page, Text, View, StyleSheet, Image, Font, Svg } from "@react-pdf/renderer";
import _ from "lodash";

import LARSIconBW from "../assets/img/brand/logo_bw.png";

import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
import { IQrCodeColleciton } from "../types/Qr";

// Create styles
const styles = StyleSheet.create({
  regularFont: {
    fontFamily: "Roboto",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 25,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  footerText: {
    fontSize: 8,
    color: "#D3D3D3",
  },
  smallLogo: {
    width: 20,
    paddingRight: 10,
  },
  qrCode: {
    paddingBottom: 5,
  },
  qrText: {
    fontSize: 12,
  },
});

Font.register({ family: "Roboto", src: RobotoRegular });

const QRCodeCollectionPdf = (collection: IQrCodeColleciton) => {
  console.log("collection:", collection);

  return (
    <Document creator="LARS" producer="ZEVS" title={collection.name}>
      <Page style={styles.body}>
        <View style={styles.footer} fixed>
          <Image style={styles.smallLogo} src={LARSIconBW} />
          <Text style={[styles.regularFont, styles.footerText]}>PDF generated with LARS</Text>
        </View>

        <View>
          {_.chunk(collection.codes, 2).map((chunk, index) => {
            return (
              <View
                style={{ flexDirection: "row", justifyContent: "space-between", paddingBottom: 40 }}
              >
                {chunk.map((code) => {
                  const dataUrl = (document.getElementById(
                    code._id
                  ) as HTMLCanvasElement).toDataURL();

                  return (
                    <View style={{ width: "48%" }}>
                      <Image key={code._id} style={styles.qrCode} src={dataUrl} />
                      <Text style={[styles.regularFont, styles.qrText]}>{code.name}</Text>
                    </View>
                  );
                })}
              </View>
            );
          })}
        </View>
      </Page>
    </Document>
  );
};

export default QRCodeCollectionPdf;
