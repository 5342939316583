import React, { useState } from "react";

// constants
import { WORKFLOW_STEPS } from "../../../constants/workflowSteps";

// utils
import classnames from "classnames";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  ListGroupItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

export default function Item({
  dragHandleProps,
  workflowId,
  siteId,
  item,
  onSuccess,
  onDelete,
  onToggle,
}) {
  const step = WORKFLOW_STEPS[item.type];
  const { t } = useTranslation();
  const history = useHistory();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <ListGroupItem disabled={!item.enabled}>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs="auto" {...dragHandleProps}>
            <i className="fas fa-grip-vertical" style={{ paddingTop: 15, paddingBottom: 15 }} />
          </Col>
          <Col xs="auto">
            <div
              className={classnames(`icon icon-shape text-white rounded-circle shadow`)}
              style={{
                background: item.enabled ? step.color : "#adb5bd",
              }}
            >
              <i className={`fas ${step.icon}`} />
            </div>
          </Col>
          <Col>{item.name}</Col>
          <Col xs="auto">
            <Dropdown
              direction="left"
              isOpen={isDropdownOpen}
              toggle={() => {
                setDropdownOpen((e) => !e);
              }}
            >
              <DropdownToggle className="btn-link">
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => {
                    history.push(
                      siteId
                        ? `/site/${siteId}/tasks/${workflowId}/${item.type}/${item._id}`
                        : `/workflows/${workflowId}/${item.type}/${item._id}`
                    );
                  }}
                >
                  <i className="fas fa-pencil-alt" />
                  {t("buttons.edit")}
                </DropdownItem>
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => {
                    onToggle(item);
                  }}
                >
                  {item.enabled ? (
                    <>
                      <i className="fas fa-toggle-off" />
                      {t("buttons.disable")}
                    </>
                  ) : (
                    <>
                      <i className="fas fa-toggle-on" />
                      {t("buttons.enable")}
                    </>
                  )}
                </DropdownItem>
                <DropdownItem
                  className="d-flex align-items-center"
                  onClick={() => {
                    onDelete(item);
                  }}
                >
                  <i className="fas fa-trash" />
                  {t("buttons.delete")}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    </ListGroupItem>
  );
}
