import React, { useEffect, useState } from "react";

// reactstrap components
import { Button, Card, CardHeader, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// constants
import { ROLES } from "../constants/roles";
import { SITE_MODAL } from "../modals/types";
import { ISite } from "../types/Site";
import { IPaginationDocs } from "../types/PaginationData";

// utils
import { useTranslation } from "react-i18next";
import { formatDate, objectIdToTimestamp } from "../helpers/date";
import { useHistory } from "react-router-dom";
import { hasSystemRole } from "../helpers/access";
import axios from "axios";
import qs from "qs";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../actions/modals";

// core components
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import SimpleHeader from "../components/Headers/SimpleHeader";
import LoadingOverlay from "../components/LoadingOverlay";

export default function Sites() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [initialized, setInitialized] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [data, setData] = useState<IPaginationDocs<ISite>>({
    docs: [],
    hasNextPage: false,
    hasPrevPage: false,
    limit: 10,
    page: 1,
    pagingCounter: 1,
    totalDocs: 0,
    totalPages: 1,
    sort: { field: "created", order: "desc" },
  });

  const onTableChange = (type: any, { page, sizePerPage, sortField, sortOrder }: any) => {
    switch (type) {
      case "sort":
        setData((d) => ({ ...d, page: 1, sort: { field: sortField, order: sortOrder } }));
        break;
      case "pagination":
        setData((d) => ({ ...d, limit: sizePerPage, page }));
        break;
      default:
        console.log("type:", type);
        break;
    }
  };

  // @ts-ignore
  const user = useSelector((state) => state.auth.user);

  const loadData = () => {
    if (isLoading) {
      return;
    }
    axios
      .get(`/api/sites`, {
        params: {
          page: data.page,
          limit: data.limit,
          sort: qs.stringify(data.sort),
        },
      })
      .then((response) => {
        setData((d) => ({ ...d, ...response.data }));
        setLoading(false);
      })
      .catch((err) => {
        console.log("err:", err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (initialized) {
      loadData();
    }
  }, [initialized, data.limit, data.page, data.sort]);

  useEffect(() => {
    setInitialized(true);
  }, []);

  return (
    <>
      <SimpleHeader
        name={
          <>
            <i className="fas fa-warehouse mr-1" />
            {t("pages.sites")}
          </>
        }
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <LoadingOverlay isLoading={isLoading}>
                <CardHeader className="border-0">
                  <Row>
                    <Col className="text-right" xs="12">
                      {hasSystemRole(user.systemRoles, ROLES.CONTRIBUTOR) ? (
                        <>
                          <Button
                            className="btn-round btn-icon"
                            color="primary"
                            id="tooltip443412080"
                            onClick={() =>
                              dispatch(
                                openModal(SITE_MODAL, {
                                  onSuccess: loadData,
                                })
                              )
                            }
                            size="sm"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-plus" />
                            </span>
                            <span className="btn-inner--text">{t("buttons.create")}</span>
                          </Button>
                          <UncontrolledTooltip delay={0} target="tooltip443412080">
                            {t("tooltips.createSite")}
                          </UncontrolledTooltip>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </CardHeader>

                <BootstrapTable
                  keyField="_id"
                  bootstrap4
                  hover
                  data={data.docs}
                  remote
                  rowStyle={{ cursor: "pointer" }}
                  defaultSorted={[
                    {
                      dataField: "_id",
                      order: "desc",
                    },
                  ]}
                  columns={[
                    { dataField: "name", text: t("fields.siteName"), sort: true },
                    { dataField: "address", text: t("fields.address"), sort: true },
                    { dataField: "contactName", text: t("fields.contactName") },
                    { dataField: "contactNumber", text: t("fields.contactNumber") },
                    {
                      dataField: "_id",
                      text: t("fields.created"),
                      sort: true,
                      formatter: (cell, row) => {
                        return formatDate(objectIdToTimestamp(cell));
                      },
                    },
                  ]}
                  rowEvents={{
                    onClick: (e, row, rowIndex) => {
                      history.push(`/site/${row._id}/dashboard/`);
                    },
                  }}
                  pagination={paginationFactory({
                    page: data.page,
                    sizePerPage: data.limit,
                    totalSize: data.totalDocs,
                  })}
                  onTableChange={onTableChange}
                />
              </LoadingOverlay>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
