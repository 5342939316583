import * as yup from "yup";

const licensePlateSchema = yup.object().shape({
  name: yup
    .string()
    .required()
    .min(3)
    .max(500),
  description: yup.string().max(2500),
  enabled: yup.bool(),
});

export default licensePlateSchema;
