// Classifiers
export const EVENT_TYPES_MODAL = "EVENT_TYPES_MODAL";
export const TRANSPORT_TYPES_MODAL = "TRANSPORT_TYPES_MODAL";

export const WORKSPACE_SETTINGS_MODAL = "WORKSPACE_SETTINGS_MODAL";

export const TASK_MODAL = "TASK_MODAL";

export const WORKFLOW_MODAL = "WORKFLOW_MODAL";
export const WORKFLOW_ADD_STEP = "WORKFLOW_ADD_STEP";
export const WORKFLOW_ADD_FORM_FIELD = "WORKFLOW_ADD_FORM_FIELD";

export const APPROVE_DIALOG_MODAL = "APPROVE_DIALOG_MODAL";
export const ADD_WIDGET_DASHBOARD_MODAL = "ADD_WIDGET_DASHBOARD_MODAL";
export const ADD_WIDGET_MODAL = "ADD_WIDGET_MODAL";
export const WORKFLOW_COUNT_MODAL = "WORKFLOW_COUNT_MODAL";

// ...
export const EVENT_MODAL = "EVENT_MODAL";
export const LOCAL_USER_MODAL = "LOCAL_USER_MODAL";
export const PROVIDER_USER_MODAL = "PROVIDER_USER_MODAL";
export const PROFILE_PASSWORD_CHANGE_MODAL = "PROFILE_PASSWORD_CHANGE_MODAL";
export const CHANGE_PASSWORD_MODAL = "CHANGE_PASSWORD_MODAL";
export const USER_SITES_MODAL = "USER_SITES_MODAL";
export const SITE_MODAL = "SITE_MODAL";
export const SITE_WORKFLOWS_MODAL = "SITE_WORKFLOWS_MODAL";
export const MANAGE_KEY_MODAL = "MANAGE_KEY_MODAL";
export const KEY_MODAL = "KEY_MODAL";
export const LOCKER_MODAL = "LOCKER_MODAL";
