import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setSidebarOpen } from "../actions/common";

import Sidebar from "../components/Sidebar/Sidebar";

export default function SidebarLayout(props) {
  const { routes, component, ...rest } = props;
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [isOpen, setOpen] = useState(true);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-hidden");
      document.body.classList.remove("g-sidenav-pinned");
    }
    dispatch(setSidebarOpen(isOpen));
  }, [isOpen]);

  return (
      <Sidebar
        {...rest}
        open={isOpen}
        routes={routes}
        onToggle={() => {
          setOpen((e) => !e);
        }}
        logo={
          user.workspace && 
          user.workspace.logo
            ? {
                src: `${process.env.REACT_APP_API_URL}uploads/${user.workspace.logo.key}`,
                alt: `${user.workspace.name} Logo`,
              }
            : 
            {
                src: require("../assets/img/brand/logo_small_1.png"),
                alt: "LARS Logo",
              }
        }
      />
  );
}
